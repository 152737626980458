.assistant-main-container {
  width: 100%;
  align-items: flex-end;
  padding: 60px 60px 40px 60px;

  .assistant-container {
    justify-content: left;
    width: 97%;
    box-shadow: var(--shadow-1);
    padding: 25px;
    border-radius: 19.125px;
    position: relative;
    .heading-4 {
      font-size: 30px;
    }

    .assistant-card-wrapper {
      padding: 24px;
      border-radius: 12px;
      gap: 32px;

      .assistant-wrapper {
        gap: 32px;

        .card-wrapper {
          position: relative;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          row-gap: 22px;
          column-gap: 32px;
          padding-bottom: 32px;
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background: #a890d3;
            left: 50%;
            bottom: 0px;
            transform: translate(-50%);
            opacity: 0.2;
          }
        }
        .no-line {
          &::after {
            display: none;
          }
        }
      }
      .card-top {
        .v-center {
          svg {
            cursor: pointer;
          }
        }
      }
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background: #a890d3;
      left: 50%;
      bottom: -30px;
      transform: translate(-50%);
      opacity: 0.2;
    }
  }
}

.assistant-card {
  padding: 12px 8px 8px 26px;
  border-radius: 9.852px;
  border: 2px solid var(--primary-light-color);

  // max-width: 321px;
  h5 {
    font-size: 18px;
  }

  p {
    color: var(--primary-light-1, #a890d3);
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
  }

  span {
    color: var(--text1);
    opacity: 0.9;
    max-width: 242px;
    margin-top: 6px;
  }

  .active-users {
    justify-content: right;
    gap: 25px;
    span {
      color: var(--primary-dark, #4b2e83);
      font-feature-settings: "clig" off, "liga" off;
      font-family: "Open Sans";
      font-size: 13.388px;
      font-style: normal;
      font-weight: 400;
      border-radius: 2.463px;
      border: 0.739px solid var(--primary-light, #7d5ba6);
      background: rgba(168, 144, 211, 0.3);
      padding: 2px 8px;
      cursor: pointer;
    }
  }
}

// Modal
.chat-agent-modal {
  .ant-modal-close {
    display: none;
  }
  .ant-modal-content {
    padding: 0;
    width: 1200px;
    // height: 556.853px;
    border-radius: 16.109px;
    background: var(--Local-Variable, #fff);
    box-shadow: 0px -4.027px 16.109px 0px rgba(0, 0, 0, 0.15);
  }
  .chat-bot-container {
  }
  .ant-modal-footer {
    display: none;
  }
}
.delete-modal {
  padding: 30px;
  p {
    color: #000;
    font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
    font-family: "Open Sans";
    font-size: 18.235px;
    font-weight: 600;
  }
}
@media only screen and (max-width: 1024px) {
  .assistant-main-container {
    padding: 60px 20px 0px 20px;

    .assistant-container {
      width: 100%;

      .assistant-card-wrapper {
        padding: 0px;
        gap: 0px;

        .assistant-wrapper {
          gap: 32px;

          .card-wrapper {
            grid-template-columns: repeat(2, 1fr);
            row-gap: 16px;
            column-gap: 16px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .assistant-main-container {
    .assistant-container {
      .assistant-card-wrapper {
        .assistant-wrapper {
          gap: 32px;
          .card-wrapper {
            grid-template-columns: repeat(1, 1fr);
            row-gap: 16px;
            column-gap: 16px;
          }
        }
      }
      .flexcol {
        flex-direction: column;
        align-items: start !important;
        justify-content: start;
        gap: 15px;
      }
    }
  }
  .chat-agent-modal {
    .ant-modal-content {
      width: 100%;
    }
  }
}
