.statistics-main-container {
  // padding: 51px 20px 124px 20px;
  padding: 160px 20px;
  background: linear-gradient(90deg, #a890d3 0%, #4b2e83 100%);

  .statistics-centered-container {
    width: 76%;
    margin: auto;
    gap: 30px;

    h5 {
      color: #fff;
      text-align: left;
      font-family: "Open Sans";
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 133.2%;
      text-transform: capitalize;
      margin-bottom: 20px;
    }

    h3 {
      color: #fff;
      font-family: "Open Sans";
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 133.2%;
      text-transform: capitalize;
    }

    // h2 {
    //   color: #fff;
    //   text-align: center;
    //   font-family: "Open Sans";
    //   font-size: 60px;
    //   font-style: normal;
    //   font-weight: 700;
    //   // line-height: 133.2%;
    //   text-transform: capitalize;
    // }
    // h3 {
    //   color: #c8c8c8;
    //   text-align: center;
    //   font-family: "Open Sans";
    //   font-size: 36px;
    //   font-style: normal;
    //   font-weight: 700;
    //   line-height: 133.2%; /* 47.952px */
    //   text-transform: capitalize;
    // }
    // .statistics-card-container {
    //   //   display: flex;
    //   //   flex-wrap: wrap;
    //   //   gap: 60px;
    //   //   // grid-template-columns: repeat(3, 1fr);
    //   //  // gap: 31px;
    //   //   margin: auto;

    //   align-items: center;
    //   display: flex;
    //   flex-wrap: wrap;
    //   gap: 30px;
    //   justify-content: center;
    //   margin: 0 auto;
    //   width: 100%;
    //   //border: 2px solid red;
    //   .statistics-card-box {
    //     display: flex;
    //     align-items: center;
    //     flex-direction: column;
    //     padding: 20px 35px;
    //     border-radius: 20px;
    //     background: #fff;
    //     box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
    //     max-width: 446px;
    //     min-height: 584px;

    //     h4 {
    //       color: var(--primary-dark, #4b2e83);
    //       text-align: center;
    //       font-family: "Open Sans";
    //       font-size: 28px;
    //       font-style: normal;
    //       font-weight: 700;
    //       line-height: 133.2%;
    //       text-transform: capitalize;
    //       padding: 45px 0px 21px 0px;
    //       min-height: 140px;
    //     }

    //     h3 {
    //     }
    //     p {
    //       color: var(--black, #000);
    //       font-family: "Open Sans";
    //       font-size: 18px;
    //       font-style: normal;
    //       font-weight: 600;
    //       line-height: 144%;
    //       text-align: left;
    //       text-transform: capitalize;
    //     }
    //   }
    // }
  }
}

@media only screen and (max-width: 1800px) {
  .statistics-main-container {
    .statistics-centered-container {
      // width: 100%;

      .statistics-card-container {
        .statistics-card-box {
          p {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .statistics-main-container {
    .statistics-centered-container {
      width: 90%;

      h2 {
        font-size: 50px;
      }

      .statistics-card-container {
        .statistics-card-box {
          padding: 20px 25px;
          max-width: 496px;

          p {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .statistics-main-container {
    .statistics-centered-container {
      h2 {
        font-size: 40px;
      }

      h3 {
        font-size: 30px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .statistics-main-container {
    padding: 40px 25px;

    .statistics-centered-container {
      gap: 30px;
      width: 100%;

      h2 {
        font-size: 30px;
      }

      h3 {
        font-size: 22px;
      }

      .statistics-card-container {
        grid-template-columns: repeat(1, 1fr);

        .statistics-card-box {
          padding: 20px 20px;
          min-height: fit-content;

          img {
            width: 80px;
          }

          h4 {
            min-height: fit-content;
            font-size: 22px;
            padding-left: 5px;
            padding-right: 5px;
            padding: 20px 0px 20px 0px;
            line-height: normal;
          }

          p {
            text-align: center;
            font-size: 15px;
          }
        }
      }
    }
  }
}
