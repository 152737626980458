.Availability-container {
  padding: 180px 10px;
  text-align: center;

  h2 {
    color: var(--Main-Logotype, #011334);
    font-family: "Open Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 58px;
  }

  h6 {
    color: #000;
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin: 20px 0px;
  }

  h5 {
    color: #000;
    font-family: "Open Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 20px;
  }
}
.removepadding {
  padding: 0px 10px 100px 10px !important;
}
@media screen and (max-width: 600px) {
  .Availability-container {
    padding: 100px 10px;
    h2 {
      font-size: 24px;
      line-height: normal;
    }

    h6 {
      font-size: 14px;
      line-height: normal;
      margin: 15px 0px;
    }

    h5 {
      font-size: 20px;
      line-height: normal;
      margin-bottom: 20px;
    }
  }
}
