.left-section {
  border-radius: 30px;

  // max-width: 70%;
  // max-height: fit-content;
  .top-bar {
    height: 53px;
    padding: 5px 24px;
    border-bottom: 1.5px solid #fff;

    span {
      li {
        width: 20px;
        height: 20px;
        border-radius: 50px;
        list-style: none;

        &:first-child {
          background-color: #ff4747;
        }

        &:nth-child(2) {
          background-color: #ffb545;
        }

        &:last-child {
          background-color: #42ca58;
        }
      }
    }

    button {
      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 160%;
      text-transform: capitalize;
      width: 260px;
      height: 37px;
      border-radius: 30px;
      background: var(--primary-light, #7d5ba6);
    }
  }

  .video-sec {
    position: relative;
    width: 100%;

    img {
      max-width: 783px;
      width: 100%;
      max-height: 654px;
    }

    .play-video-btn {
      position: absolute;
      top: 57%;
      right: 21%;
      transform: translate(-50%, -70%);
      width: 27%;
      height: 32%;
      background-image: url(../../../public/assets/images/video-play.svg);
      background-repeat: no-repeat;
      transition: 0.3s ease-out;

      &:hover {
        background-image: url(../../../public/assets/images/video-play-fill.png);
      }
    }
  }
}

.hide {
  display: none !important;
}

@media only screen and (max-width: 900px) {
  .left-section {
    .video-sec {
      width: 100%;

      img {
        width: 100%;
        max-height: 654px;
      }

      .play-video-btn {
        position: absolute;
        top: 65% !important;
        right: 21%;
        transform: translate(-50%, -70%);
        width: 27%;
        height: 32%;
        background-image: url(../../../public/assets/images/video-play.svg);
        background-repeat: no-repeat;
        transition: 0.3s ease-out;

        &:hover {
          background-image: url(../../../public/assets/images/video-play-fill.png);
        }
      }
    }
  }
}
