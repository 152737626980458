.provideFeatures-main-container {
  padding: 74px 10px 142px 10px;
  background: #fff;
 // margin-top: 80px;
  position: relative;
  overflow: hidden;
  h2 {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 40.523px;
    font-style: normal;
    font-weight: 700;
    line-height: 58.8px;
    padding-bottom: 40px;
  }

  .provideFeatures-centered-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 25px;
    width: 92%;
    margin: auto;
  }

  .provideFeatures-inner-box {
  }
  // &::before {
  //   content: "";
  //   position: absolute;
  //   background: radial-gradient(
  //     49.55% 45.34% at 50% 0%,
  //     rgba(168, 144, 211, 0.8) 0%,
  //     rgba(75, 46, 131, 0.8) 0.01%,
  //     rgba(255, 255, 255, 0) 100%
  //   );
  //   width: 100%;
  //   height: 24px;
  //   top: 0;
  // }
}
@media only screen and (max-width: 1500px) {
  .provideFeatures-main-container .provideFeatures-centered-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 25px;
    width: 95%;
    margin: auto;
  }
}

@media only screen and (max-width: 1300px) {
  .provideFeatures-main-container .provideFeatures-centered-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 1024px) {
  .provideFeatures-centered-container {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media only screen and (max-width: 700px) {
  .provideFeatures-main-container {
    padding: 40px 10px;

    h2 {
      font-size: 24px;
      padding-bottom: 10px;
    }

    .provideFeatures-centered-container {
      grid-template-columns: repeat(1, 1fr) !important;
      width: 100% !important;
      padding: 0px 10px 0px 10px;
    }
  }
}
