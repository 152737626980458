.platformWrapper {
  padding: 20px;
   //border: 2px solid purple;
  background: var(--black-grad, linear-gradient(90deg, #000 0%, #474747 100%));
  padding-top: 130px;

  .headingDiv {
    padding: 80px 20px;
    width: 50%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    .mainHead {
      color: #fff;
      text-align: center;
      font-family: "Open Sans";
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 58.8px; /* 122.5% */
    }
    .subHead {
      color: var(--primary-light-1, #a890d3);
      text-align: center;
      font-family: "Open Sans";
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 58.8px; /* 163.333% */
    }
  }
}

@media screen and (max-width: 600px) {
  .platformWrapper {
    padding-top: 40px;
    .headingDiv {
      padding: 0px;
      width: 100%;
      .mainHead{
        font-size: 30px;
      }
      .subHead{
        font-size: 25px;
        line-height: normal;
      }
    }
  }
}
