.testimonialCard {
  width: 443.073px;
  height: 213px;
  padding: 23px 30px;
  border-radius: 16.26px;
  background: linear-gradient(
    92deg,
    #f7f6fb 1.11%,
    #f7f6fb 42.04%,
    #f7f6fb 70.51%,
    rgba(247, 246, 251, 0.6) 98.49%
  );
  box-shadow: 0px 16px 13px 0px rgba(0, 0, 0, 0.2);
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 1px solid green;

  .leftArea {
    width: 20%;
    // border: 2px solid red;
    display: flex;
    align-items: flex-start;
    justify-content: start;
    height: 100%;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .rightArea {
    width: 80%;
    //  border: 2px solid blue;
    h5 {
      color: #000;

      font-family: "Open Sans";
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .grayPara {
      color: #b9b9b9;
      font-family: "Open Sans";
      font-size: 13.008px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .grayPara2 {
      color: #b9b9b9;
      font-family: "Open Sans";
      font-size: 13.008px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      position: relative;
      padding-left: 20px;

      &::before {
        position: absolute;
        content: url("../../../../src/images/sliderAfter.svg");
        left: 2px;
      }
    }
    p {
      color: #1a1b1d;
      font-family: "Open Sans";
      font-size: 9.756px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.195px;
    }
  }
}
@media screen and (max-width: 600px) {
  .testimonialCard {
    width: 100%;
    flex-direction: column;
    height: auto;
    padding: 20px;
    .leftArea {
      align-items: center;
      justify-content: center;
      // border: 1px solid green;

      img {
        width: 100%;
        height: 100%;
        // object-fit: contain;
      }
    }
    .rightArea {
      width: 100%;
      .grayPara2 {
        padding-left: 0;
      }
      h5 {
        text-align: center;
        padding: 10px 0px;
      }
      p {
        text-align: center;
      }
    }
  }
}
