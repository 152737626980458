.chooseCardWrapper {
  width: 100%;
  padding: 20px 10px 25px 26px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);

  .topHeading {
    color: #000;
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px;
    max-width: 343px;
  }
  .choose-para {
    color: #000;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 158.5%;
    max-width: 465px;
  }
}

@media screen and (max-width: 600px) {
  .chooseCardWrapper {
    gap: 7px;
    padding: 20px;
    .topHeading{
        line-height: normal;
        font-size: 20px;
        padding: 0;
    }
  }
}
