.AccountSetting-main-container {
    color: var(--black);
    width: 100%;
    max-width: 1000px;
    padding: 30px 24px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
    margin-top: 30px;

    .AccountSetting-userImg-box {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        span {
            width: 91.2px;
            height: 91.2px;
            border-radius: 500px;
            overflow: hidden;
            background: var(--primary-gradient-bg);
        }

        h5 {
            color: var(--black);
            font-family: "Open Sans";
            font-size: 22px;
            font-weight: 600;
            margin-top: 10px;
        }
    }

    form {
        width: 100%;
        max-width: 800px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 25px;
        column-gap: 50px;
        margin: auto;
        margin-top: 60px;

        label {
            position: relative;
            display: flex;
            flex-direction: column;
            color: var(--Light-Gray-7, #757575);
            font-family: "Open Sans";
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            letter-spacing: 0.291px;

            .eye-Icon {
                width: 24.992px;
                height: 19.559px;
                position: absolute;
                right: 0;
                bottom: 0;
                top: 50%;
            }

            .ResetPassword {
                color: var(--Light-Gray-7, #757575);
                font-family: "Open Sans";
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0.291px;
                position: absolute;
                right: 0;
                bottom: -20px;
            }

            input {
                border: none;
                border-bottom: 1.165px solid #A890D3 !important;
                color: var(--black);
                font-family: "Open Sans";
                font-size: 18.648px;
                font-weight: 500;
            }
        }
    }
}

.AccountSetting-btn-container {
    width: 100%;
    max-width: 1000px;
    margin: 20px 0px;

    .theme-btn-tran {
        padding: 8px 30px;
    }
}


/* Gradient button styling */
.primary-gradient-bg {
    background: var(--primary-gradient-bg); /* Example gradient */
    color: #fff !important; /* White text */
    border: none; /* Remove border */
    padding: 8px 16px; /* Button padding */
    font-weight: 600; /* Bold text */
    border-radius: 4px; /* Rounded corners */
    transition: background 0.3s ease; /* Smooth transition for hover */
  }
  
  .primary-gradient-bg:hover {
    background: var(--primary-gradient-bg); /* Inverted gradient on hover */
  }
  
  /* Optional cancel button styling */
  .cancel-btn {
    color: #666 !important; /* Grey text */
    border: none;
  }
  

@media only screen and (max-width: 700px) {
    .AccountSetting-main-container {
        padding: 12px;
        form {
            grid-template-columns: repeat(1, 1fr);
            h5{
                font-size: 15px !important;
            }
        }
    }
}