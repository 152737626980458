.table-pillbg-white {
  color: var(--text1);
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border-radius: 10px;
  background: #fff;
  padding: 8px;
  box-shadow: var(--shadow-1);
}

.b-lightwhite {
  border-bottom: 1px solid red !important;
}

@media only screen and (max-width: 650px) {
  .table-div {
    overflow-x: scroll;
    table {
      width: 600px;
      overflow: scroll;
      table-layout: fixed;
    }
  }
}
