.LLM-main-container {
  width: 100%;
  max-width: 1134px;
  padding: 24px;
  border-radius: 12px;
  box-shadow: var(--shadow-1);
  h5 {
    color: var(--black);
    text-overflow: ellipsis;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .LLM-inner-container {
    margin-top: 30px;
    padding: 24px;
    border-radius: 8px;
    background: var(--tabele-head-bg);

    .LLM-openAiHead-container {
      display: flex;
      justify-content: space-between;

      span {
        color: var(--black);
        font-family: "Open Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%;

        img {
        }
      }
    }

    h6 {
      color: var(--black);
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    p {
      color: #f00;
      text-align: right;
      font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      opacity: 0.6;
      cursor: pointer;
    }

    .LLM-input-box {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      border: 1px solid #e5e5e5 !important;

      input {
        width: 100%;
        border-radius: 8px;
        background-color: transparent;
        border: 1px solid transparent;
        color: var(--text1);
        padding-right: 45px;
      }

      svg {
        position: absolute;
        right: 20px;
        cursor: pointer;
      }
    }

    h5 {
      color: var(--text1);
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 20px 0px;
    }

    .theme-btn-light {
      padding: 8px 50px;
    }
  }
}

@media screen and (max-width: 600px) {
  .LLM-main-container {
    padding: 10px;
  }
}
