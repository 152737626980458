.Integration-main-container {
  max-width: 1130px;
  margin: auto;
  padding: 40px 15px 40px 15px;

  h5 {
    color: var(--black);
    text-overflow: ellipsis;
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .integration-tabs-container {
    max-width: 435px;
    margin: 30px 0px;

    .nav-switch {
      display: flex;
      border-radius: 500px;
      width: auto;
      border: 1px solid var(--primary-light-color);
      button {
        padding: 16px;
        width: 100%;
        color: var(--primary-light-color);
        font-family: "Open Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        text-align: center;
      }

      .active {
        border-radius: 500px;
        background: var(--primary-light-1, var(--primary-light-color));
        color: var(--white);
        font-weight: 600;
      }
    }
  }
}

@media only screen and (max-width: 1500px) {
  .Integration-main-container {
    max-width: 900px;
    width: 100%;
  }
}
@media only screen and (max-width: 800px) {
  .integration-tabs-container {
    max-width: 100% !important;
    margin: 30px 0px;
  }
}
@media only screen and (max-width: 600px) {
  .Integration-main-container {
    padding: 20px;
  }
}
