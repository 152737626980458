.WhyFabriCareCard-inner-box {
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
    }

    h5 {
      color: #fff;
      text-align: center;
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      padding: 20px 0px;
    }
  }

  p {
    color: #fff;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}


@media only screen and (max-width: 700px) {
  .WhyFabriCareCard-inner-box {
    div {
      h5 {
        line-height: 10px;
      }
    }

    p {
      font-size: 14px;
      line-height: 22px;
    }
  }
}