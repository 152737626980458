.WhyFabriCare-main-container {
  padding: 61px 20px 383px 20px;
  h2 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 40.523px;
    font-style: normal;
    font-weight: 700;
    line-height: 58.8px;
    padding-bottom: 93px;
  }

  .WhyFabriCare-centered-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 25px;
    width: 68%;
    margin: auto;
  }
}

@media only screen and (max-width: 1024px) {
  .WhyFabriCare-main-container {
    .WhyFabriCare-centered-container {
      grid-template-columns: repeat(2, 1fr);
      gap: 25px;
      width: 80%;
    }
  }
}
@media only screen and (max-width: 700px) {
  .WhyFabriCare-main-container {
    padding: 0px 20px 60px 20px;
    height: 100%;
    h2 {
      font-size: 24px;
      padding: 40px 0px;
      line-height: 30px;
    }
    .WhyFabriCare-centered-container {
      grid-template-columns: repeat(1, 1fr);
      gap: 35px;
      width: 100%;
    }
  }
}
