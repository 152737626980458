.talk-main-container {
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 87px 20px 50px 20px;
  position: relative;
  overflow: hidden;
  .talk-centered-container {
    display: flex;
    justify-content: center;
    width: 70%;
    margin: auto;

    .talk-left-container {
      width: 50%;

      h2 {
        max-width: 549px;
        overflow: hidden;
        color: var(--black, #000);
        font-family: "Open Sans";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 133.2%;
        text-transform: capitalize;
        margin-bottom: 20px;

        strong {
          // color: var(--black, #000);
          font-family: "Open Sans";
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: 133.2%;
          text-transform: capitalize;
          &:first-child {
            color: #7d5ba6;
          }
          &:last-child {
            color: #a890d3;
          }
        }
      }

      p {
        max-width: 663px;
        color: #000;
        font-family: "Open Sans";
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
      }

      ul {
        li {
          display: flex;
          padding: 12px 60px 13px 15px;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
          border-radius: 44px 0px 0px 44px;
          background: linear-gradient(
            270deg,
            rgba(255, 255, 255, 0) 0%,
            #a890d3 100%
          );
          width: fit-content;
          margin-bottom: 15px;
          color: var(--primary-dark, #4b2e83);
          font-family: "Open Sans";
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 160%;
          text-transform: uppercase;
        }
      }
    }

    .talk-right-container {
      display: flex;
      width: 50%;
    }
  }
  &::before {
    content: "";
    position: absolute;
    background: radial-gradient(
      49.55% 45.34% at 50% 0%,
      rgba(168, 144, 211, 0.8) 0%,
      rgba(75, 46, 131, 0.8) 0.01%,
      rgba(255, 255, 255, 0) 100%
    );
    width: 100%;
    height: 24px;
    top: 0;
  }
}
@media only screen and (max-width: 1440px) {
  .talk-main-container {
    padding: 87px 60px 200px 60px;
    .talk-centered-container {
      gap: 30px;
      width: 100%;
      .talk-right-container {
        width: 50%;
      }
      .talk-left-container {
        h2 {
          font-size: 36px;
          strong {
            font-size: 36px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .talk-main-container {
    .talk-centered-container {
      .talk-left-container {
        h2 {
          font-size: 30px;
          strong {
            font-size: 30px;
          }
        }
        p {
          font-size: 18px;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .talk-main-container {
    padding: 40px 20px;
    .talk-centered-container {
      width: 100%;
      flex-direction: column;
      .talk-left-container {
        width: 100%;
        h2 {
          font-size: 24px;
          strong {
            font-size: 24px;
          }
        }
        p {
          font-size: 14px;
        }
        ul {
          li {
            padding: 10px 50px 10px 14px;
            font-size: 14px;
          }
        }
      }

      .talk-right-container {
        width: 100%;
      }
    }
  }
}
