.fuctionalityWrapper {
  padding: 100px 20px;
  // border: 3px solid green;
  margin-top: 100px;
  background-color: white;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background: radial-gradient(
      49.55% 45.34% at 50% 0%,
      rgba(168, 144, 211, 0.8) 0%,
      rgba(75, 46, 131, 0.8) 0.01%,
      rgba(255, 255, 255, 0) 100%
    );
    width: 100%;
    height: 24px;
    top: 0;
  }

  h3 {
    text-align: left;
    font-size: 32px;
    color: #000;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    width: 80% !important;
    margin: auto;
    margin-bottom: 50px;
  }

  .listItem {
    width: 80%;
    margin: 0 auto;
    //  border: 1px solid black;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 36px;

    .order {
      display: flex;
      justify-content: start;
      align-items: center;
      padding: 30px;
      gap: 10px;
      border-radius: 500px;
      background: #fff;
      box-shadow: 0px 0px 16px 0px rgba(75, 46, 131, 0.3);

      p {
        color: #4b2e83;
        font-family: "Open Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
      }
    }
  }
}

@media screen and (max-width: 1820px) {
  .fuctionalityWrapper {
    .listItem {
      width: 95%;
    }
  }
}

@media screen and (max-width: 1540px) {
  .fuctionalityWrapper .listItem .order {
    width: 347px;
  }
}

@media screen and (max-width: 1250px) {
  .fuctionalityWrapper {
    .listItem {
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
      place-items: center;
      column-gap: 0;
    }
  }
}

@media screen and (max-width: 600px) {
  .fuctionalityWrapper {
    padding: 60px 20px;

    h3 {
      font-size: 20px;
      margin-bottom: 20px;
      margin-left: 5px;
    }

    .listItem {
      grid-template-columns: repeat(1, 1fr);
      gap: 15px;
      width: 100%;

      .order {
        width: 100%;
        padding: 15px;
        img {
          width: 30px;
          height: 30px;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }
}
