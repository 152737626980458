.Dashboard-main-container {
  color: var(--white);
  background: var(--white);
  // background: linear-gradient(73deg, #000 -1.71%, #474747 198.24%);
  height: 100vh;

  .right_content {
    width: calc(100vw - 300px);
    margin-left: 300px;
  }
  &::-webkit-scrollbar {
    background: #bba9dc;
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--primary-gradient-bg);
    border-radius: 12px;
  }
}

@media only screen and (max-width: 1310px) {
  .Dashboard-main-container {
    // height: 100vh;
    .right_content {
      width: 100vw;
      margin-left: 0px;
    }
  }
}
