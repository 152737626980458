/* Assistants.scss */

.modal-knowledge-base {
    display: flex;
    gap: 20px;
  
    .knowledge-container {
      flex: 2;
      max-height: 700px; /* Adjust this value as needed */
      overflow-y: auto;
      padding-right: 15px; /* To ensure scrollbar doesn't overlap content */
      margin-left: 10px;
  
      .knowledge-content {
        .knowledge-item {
          margin-bottom: 20px;
  
          h2 {
            font-size: 25px;
            font-weight: bold;
          }
  
          h3 {
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 10px;
          }
  
          .fileURL-container {
            margin-bottom: 10px;
          }
  
          .ant-table-wrapper {
            margin-bottom: 20px;
          }
        }
      }
    }
  
    .chatbot-container {
      flex: 1;
      background: #f9f9f9;
      border-radius: 10px;
      padding: 20px;
    }
  }
  