.logtable-div {
  border-radius: 0px;
 
  height: 100vh;
  table {
    thead {
      background: var(--tabele-head-bg);
      color: #cbd5e0;
      font-family: "Open Sans";
      font-size: 11.182px;
      font-style: normal;
      font-weight: 700;
      line-height: 16.773px;
      overflow-x: scroll;
      tr {
        th {
          max-width: 89px;
          padding: 10px 14px;
          color: var(--black);
          font-weight: 700;
          font-family: "Open Sans";
          font-size: 12px;
        }
      }
    }

    tbody {
      background-color: transparent !important;

      tr {
        background-color: transparent !important;

        td {
          padding: 14px;
          color: var(--text1);
          font-family: "Open Sans";
          font-size: 11.182px;
          font-style: normal;
          font-weight: 500;
        }

        .tr-dark {
          color: var(--primary-light-1, #a890d3);
          text-decoration-line: underline;
          text-decoration: none;
        }
      }
    }
  }
}

.logdetailtable-div {
  border-radius: 0px;

  table {
    thead {
      border-radius: 10% !important;
      color: #cbd5e0;
      font-family: "Open Sans";
      font-size: 11.182px;
      font-style: normal;
      font-weight: 700;
      line-height: 16.773px;
      background-color: #f8f8f8;

      tr {
        th {
          max-width: 89px;
          padding: 14px;
          color: var(--black);
          font-family: "Open Sans";
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 160%;
        }
      }
    }

    tbody {
      background-color: transparent !important;

      tr {
        background-color: transparent !important;
        td {
          padding: 20px 15px;
          color: var(--text1);
          font-family: "Open Sans";
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16.773px;
        }
      }
    }
  }
}


.Messages-chatbot{
width: auto;
height: 50vh;
border: 2px solid #000;
overflow-y: scroll;
}

.message-container {
  display: flex;
  margin-bottom: 10px;
}

.human-message {
  justify-content: flex-end; /* Align human messages to the right */
}

.ai-message {
  justify-content: flex-start; /* Align AI messages to the left */
}

.message {
  max-width: 60%; /* Limit the message bubble width */
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
}

.human {
  background-color: #a890d3; /* Light green for human messages */
  color: #000;
  border-radius: 15px 15px 0 15px; /* Rounded top-right */
}

.ai {
  background-color: #f0f0f0; /* Light gray for AI messages */
  color: #000;
  border-radius: 15px 15px 15px 0; /* Rounded top-left */
}

/* Optional: Add some spacing between the chat bubbles and container */
.message-container .message {
  margin: 5px 10px;
}
