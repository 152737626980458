.ElevenLabs-main-container {
  width: 100%;
  max-width: 1134px;
  padding: 24px;
  border-radius: 12px;
  box-shadow: var(--shadow-1);


  .eleven-input-box {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;


  
    svg {
      position: absolute;
      right: 20px;
      cursor: pointer;
    }
  }


  h5 {
    color: var(--black);
    text-overflow: ellipsis;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  p {
    color: var(--text1);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 20px 0px;
  }

  input {
    margin-top: 10px;
    border-radius: 9.977px;
    padding: 20px;
    background: var(--light-bg);
    color: var(--text1);
    font-weight: 500;
  }

  input::placeholder {
    color: var(--text1);
    opacity: 0.5;
  }
}
