.logs-main-container {
  padding: 43px 60px 60px 0px;
  width: 100%;
  align-items: center;

  .logs-inner {
    justify-content: left;
    width: 87%;
    gap: 26px;

    .logs-details-container {
      .details-top-bar {
        border-radius: 11.182px;
        padding: 26.506px;
        box-shadow: var(--shadow-1);

        span {
          font-size: 15.462px;

          h3 {
            font-size: 26.506px;
          }
        }

        p {
          font-family: "Open Sans";
          font-size: 11.044px;
          font-style: normal;
          font-weight: 600;
          padding: 4.418px 11.044px;
          border-radius: 110.44px;
          background: rgba(255, 255, 255, 0.1);
          height: fit-content;
          width: fit-content;
        }
      }

      .calls-logs {
        padding: 22.364px;
        gap: 18px;
        box-shadow: var(--shadow-1);
        border-radius: 11.182px;
        margin-top: 3px;

        h5 {
          color: var(--black);
          font-family: "Open Sans";
          font-size: 18.637px;
          font-style: normal;
          font-weight: 600;
        }

        button {
          color: var(--black);
          text-align: center;
          font-size: 11.182px;
        }

        .getback-btn {
          color: var(--primary-light-color);
          font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
          font-size: 12.125px;
          font-style: normal;
          font-weight: 400;
          line-height: 10.125px;
          border-radius: 500px;
          border: 0.844px solid var(--primary-light-1, #a890d3);
          padding: 12px 24px;
          width: fit-content;
          height: fit-content;
        }

        .loading-spinner {
          display: inline-block;
          width: 50px;
          height: 50px;
          border: 3px solid rgba(195, 195, 195, 0.6);
          border-radius: 50%;
          border-top-color:  rgb(168, 144, 211);
          animation: spin 1s ease-in-out infinite;
          margin: 0 auto;
        
          @keyframes spin {
            to {
              transform: rotate(360deg);
            }
          }
        }

        .filters {
          display: flex;
          gap: 1rem;
          margin-bottom: 1rem;
          align-items: center;
        
       
          label {
            margin-right: 0.5rem;
            color: #000 !important; /* Set label text color to black */
          }
        
          input[type="date"] {
            padding: 0.3rem;
            border: 1px solid #ccc;
            border-radius: 4px;
            color: #000 ;
          }
        
          .search-bar {
            flex-grow: 1;
        
            input[type="text"] {
              width: 100%;
              padding: 0.5rem;
              border: 1px solid #ccc;
              border-radius: 4px;
              font-size: 1rem;
              color: #000 ;
            }
          }
        }


      }
    }
  }
}


@media only screen and (max-width: 1024px) {
  .logs-main-container {
    padding: 43px 15px 60px 15px;
    width: 100%;

    .logs-inner {
      width: 100%;
    }
  }
}