.provideFeatures-inner-box {
  border-radius: 12px;
  background: rgba(125, 91, 166, 0.2);
  padding: 20px;

  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-direction: column;

  .img-wrapper {
    position: relative;
    width: fit-content;
    text-align: center;
    margin: 0 auto;

    .provideFeatures-bgImg {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 72px;
      height: 72px;
      padding: 10px;
      border-radius: 10px;
      background: var(--primary-dark, #4b2e83);
      transition: 0.8s ease-in-out;
    }

    img {
      position: absolute;
      width: 45px;
      height: 45px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      .provideFeatures-bgImg {
        transform: rotate(45deg);
      }
    }
  }

  h5 {
    color: var(--Gray-900, #101828);
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    padding: 15px 0px;
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;

    li {
      color: var(--Gray-600, #475467);
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

@media only screen and (max-width: 700px) {
  .provideFeatures-inner-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 20px 10px;
  }

  h5 {
    color: var(--Gray-900, #101828);
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }

  ul {
    padding-left: 0px !important;

    li {
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}
