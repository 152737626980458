.sidebar-main-container {
  display: flex;
  justify-content: space-between;
  width: 335px;
  flex-direction: column;
  position: fixed;
  overflow: auto;
  &::-webkit-scrollbar {
    background: #bba9dc;
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--primary-gradient-bg);
    border-radius: 12px;
  }
  &::after {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    width: 1px;
    height: 100vh;
    background: linear-gradient(
      180deg,
      rgba(75, 46, 131, 0) -13.24%,
      #a890d3 43.24%,
      rgba(75, 46, 131, 0) 108.47%
    );
  }

  .sidebar-logo-container {
    padding: 15px 0px 10px 0px;

    img {
      margin: auto;
    }
  }

  .sidebar-options-container {
    padding: 15px;
    padding-right: 31px;
    ul {
      li {
        .dashLinks {
          border-radius: 500px;
          padding: 10px 15px;
          background: var(--white);
          box-shadow: var(--shadow-1);
          color: var(--text1);
          font-family: "Open Sans";
          font-size: 15.633px;
          font-style: normal;
          font-weight: 400;
          margin-bottom: 25px;
          transition: var(--tranition-half);

          // opacity: 0.2;

          svg {
            margin-right: 10px;

            path {
              // fill: var(--black);
            }
          }

          span {
            margin-top: 2px;
          }

          // &:hover {
          //   color: var(--white);
          //   background: var(--primary-gradient-bg);

          //   .dashLinkicon {
          //     path {
          //       fill: var(--white);
          //       stroke: none;
          //     }
          //   }

          //   svg {
          //     path {
          //       stroke: var(--white);
          //     }
          //   }
          // }

          &.active {
            color: var(--white);
            background: var(--primary-gradient-bg);

            .dashLinkicon {
              path {
                fill: var(--white);
                stroke: none;
              }
            }

            svg {
              path {
                stroke: var(--white);
              }
            }
          }
        }
      }
    }
  }

  .sidebar-accountSetting-container {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0px 15px 15px 15px;
    cursor: pointer;

    .userImg-box {
      width: 38px;
      height: 38px;
      border-radius: 500px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .accountSetting-text-box {
      h6 {
        color: var(--black);
        font-size: 15.633px;
        line-height: normal;
        font-weight: 400;
      }

      p {
        color: var(--text1);
        font-size: 13.4px;
        font-weight: 400;
        line-height: normal;
      }
    }

    svg {
      position: absolute;
      right: 20px;
    }
  }

  .themeGlow-btn {
    margin: 0px 15px 15px 15px;
  }
  .sidebar-footer{
    display: flex;
  flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    gap: 10px;
  }
}

@media only screen and (max-width: 1310px) {
  .sidebar-main-container {
    .sidebar-options-container {
      padding: 15px 0px 15px 0px;
    }

    .sidebar-logo-container {
      padding: 0px 0px 10px 0px;
    }
  }

  .sidebar {
    position: absolute;
    top: 0px;
    left: 0;
    height: 100% !important;
    width: 0px !important;
    overflow: hidden;
    transition: width 0.3s ease;
    padding: 0px 0px !important;
    opacity: 0;
    z-index: 999;
    overflow: auto;
  }

  .sidebar.open {
    padding: 30px 16px !important;
    width: 300px !important;
    opacity: 1;
    background-color: #fff;
    box-shadow: var(--shadow-1);
  }
}
