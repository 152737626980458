.pricingCard-box {
  border-radius: 19.014px;
  background: #fff;
  box-shadow: 0px 6.338px 9.507px -3.169px rgba(16, 24, 40, 0.03),
    0px 19.014px 25.352px -6.338px rgba(16, 24, 40, 0.08);
  max-width: 411px;
  position: relative;
  z-index: 2;
  .pricing-top {
    padding: 38px 38px 0px 38px;
    .price-pill {
      width: fit-content;
      text-align: center;
      font-family: "Open Sans";
      font-size: 12.845px;
      font-style: normal;
      font-weight: 600;
      line-height: 28.521px;
      display: flex;
      padding: 3.169px 19.014px;
      align-items: center;
      gap: 12.676px;
      border-radius: 38.029px;
    }

    h5 {
      color: var(--primary-dark, #4b2e83);
      text-align: left;
      font-family: "Open Sans";
      font-size: 47.536px;
      font-style: normal;
      font-weight: 600;
      line-height: 60.212px;
      padding: 23px 0px 19px 0px;

      span {
        color: var(--Blue-Grey-Blue-Grey-400, #829ab1);
        text-align: center;
        font-family: "Open Sans";
        font-size: 19.014px;
        font-style: normal;
        font-weight: 400;
        line-height: 28.521px;
      }
    }

    p {
      color: var(--Blue-Grey-Blue-Grey-600, #486581);
      font-family: "Open Sans";
      font-size: 15.845px;
      font-style: normal;
      text-align: left;
      font-weight: 400;
      line-height: 28.521px;
      padding-bottom: 25px;
    }
  }
  .pricing-bottom {
    padding: 0px 35px 38px 35px;
    ul {
      padding: 25px 0px;

      li {
        display: flex;
        align-items: start;
        color: var(--Blue-Grey-Blue-Grey-600, #486581);
        font-family: "Open Sans";
        font-size: 15.845px;
        font-style: normal;
        font-weight: 400;
        line-height: 28.521px;
        margin-bottom: 25px;

        img {
          margin-right: 15px;
          width: 25.352px;
          height: 25.352px;
        }
      }
    }

    .select-lite-btn {
      color: var(--White, #fff);
      font-family: Inter;
      font-size: 22.183px;
      font-style: normal;
      font-weight: 600;
      line-height: 31.69px;
      display: flex;
      padding: 15.845px 25.352px;
      justify-content: center;
      align-items: center;
      gap: 12.676px;
      width: 100%;
      border-radius: 12.676px;
      background: var(--primary-light, #7d5ba6);
      box-shadow: 0px 1.585px 3.169px 0px rgba(16, 24, 40, 0.05);
    }
  }
}
.bgshadow {
  box-shadow: 0px 0px 228.4px -19.014px #7d5ba6;
  position: relative;
  z-index: 1;
}
.pillcolor1 {
  background: var(--light-blue-vivid-light-blue-vivid-050, #e3f8ff);
  color: var(--light-blue-vivid-light-blue-vivid-900, #035388);
}
.pillcolor2 {
  background: var(--pink-vivid-pink-vivid-050, #ffe3ec);
  color: var(--pink-vivid-pink-vivid-900, #620042);
}
.pillcolor3 {
  background: var(--Teal-Teal-050, #effcf6);
  color: var(--teal-vivid-teal-vivid-900, #004440);
}
@media only screen and (max-width: 600px) {
  .pricingCard-box {
    h5 {
      font-size: 32px;

      span {
        font-size: 16.014px;
      }
    }

    p {
      font-size: 13.845px;
      font-weight: 500;
    }

    ul {
      li {
        align-items: center;
        font-size: 13.845px;

        img {
          width: 20.352px;
          height: 20.352px;
        }
      }
    }

    .select-lite-btn {
      padding: 9.845px 25.352px;
      font-size: 18px;
    }
  }
}
