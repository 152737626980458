.webhooks-container {
  .Agent-details-container {
    label {
      font-size: 16px;
    }

    .copyUrl-box {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0px 10px;
      border-radius: 12px;
      background-color: var(--light-bg);

      p {
        color: #000;
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        opacity: 0.5;
      }

      input {
        border: none;
        border-radius: 12px;
        width: 100%;
        margin-top: 0px;
        padding: 12px 10px 12px 10px;
      }

      span {
        color: var(--white);
        text-align: center;
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        padding: 4px 10px;
        white-space: nowrap;
        width: 140px;
        border-radius: 500px;
        background: var(--primary-light, #7d5ba6);
        cursor: pointer;
        text-decoration: underline !important;
      }
    }

    /* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px; /* reduced from 60px */
  height: 24px; /* reduced from 34px */
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 24px; /* adjusted to maintain the rounded look */
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px; /* reduced from 26px */
  width: 18px; /* reduced from 26px */
  left: 3px; /* slightly less offset */
  bottom: 3px; /* slightly less offset */
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #a890d3;
}

input:checked + .slider:before {
  transform: translateX(16px); /* adjusted to fit the new width */
}

  }
  button {
    margin-top: 20px;
  }
}
.copy-item {
  text-decoration: none !important;
}
@media only screen and (max-width: 650px) {
}


