.footer-main-container {
  background: #000;
  padding: 30px 20px;

  .footer-centered-container {
    width: 80%;
    margin: auto;
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      width: 118.876px;
      height: 76.77px;
    }

    ul {
      display: flex;
      padding: 30px 0px;

      li {
        padding: 0px 15px;
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-decoration: underline;
        transition: all 0.5s ease-in-out;

        &:hover {
          color: #7d5ba6;
        }
      }
    }

    .socailMedia-footer-icon-box {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;

      .socailMedia-footer-icon-link {
        background-color: #7D5BA6;
        width: 37px;
        height: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        border-radius: 50px;
        cursor: pointer;

        .footer-icon- {
          width: 22px;
          height: 22px;
        }
      }
    }

    .footer-line {
      width: 90%;
      height: 1px;
      margin-top: 30px;
      background-color: #7d5ba6;
      opacity: 0.5;
    }

    p {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      padding: 40px 0px 10px 0px;
    }
  }
}
@media only screen and (max-width: 600px) {
  .footer-main-container {
    padding: 30px 20px;
    .footer-centered-container {
      width: 100%;
      ul {
        display: flex;
        padding: 30px 0px;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        align-items: center;
        li {
          font-size: 14px;
        }
      }

      .socailMedia-footer-icon-box {
        .socailMedia-footer-icon-link {
          width: 35px;
          height: 35px;

          .footer-icon- {
            width: 16px;
            height: 16px;
          }
        }
      }
      p {
        font-size: 12px;
        padding: 30px 0px 10px 0px;
        text-align: center;
        max-width: 170px;
      }
    }
  }
}
