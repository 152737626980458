.Subscription-main-container {
    color: var(--black);
    width: 100%;
    max-width: 1150px;
    padding: 24px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);


    .Subscription-tabs-container {
        padding-top: 30px;

        .Subscription-tabs-box {
            .themeGlow-btn {
                padding: 10px 35px;
            }

            .theme-btn-tran {
                padding: 10px 35px;
            }
        }

        .Subscription-card-container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            margin-top: 30px;
        }

    }
}

@media only screen and (max-width: 900px) {
    .Subscription-card-container {
        grid-template-columns: repeat(2, 1fr) !important;
        gap: 10px !important;
    }
}

@media only screen and (max-width: 650px) {
    .Subscription-main-container {
        padding: 10px;
        .Subscription-card-container {
            grid-template-columns: repeat(1, 1fr) !important;
        }

        .Subscription-tabs-box {
            .themeGlow-btn {
                padding: 10px 10px !important;
                font-size: 12px !important;
            }

            .theme-btn-tran {
                padding: 10px 10px !important;
                font-size: 12px !important;
            }
        }
    }
}