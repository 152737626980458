.tableSmrt-div {
  border: none !important;

  table {
    thead {
      background: var(--tabele-head-bg) !important;
      border: none !important;

      tr {
        border: none !important;

        th {
          padding-top: 20px !important;
          padding-bottom: 20px !important;
          border: none !important;
          color: var(--black);
          font-size: 12px;
          font-weight: 700;
          letter-spacing: 0.2px;
        }
      }
    }

    tbody {
      tr {
        border: none !important;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;

        th {
          color: var(--text1);
          font-size: 12px;
          font-weight: 400;
          border-bottom: 1px solid #e5e5e5;
        }

        td {
          color: var(--text1);
          font-size: 12px;
          font-weight: 400;
          border-bottom: 1px solid #e5e5e5;
          span {
            gap: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .tableSmrt-div {
    table {
      thead {
        tr {
          th {
            justify-content: flex-start;
          }
        }
      }
    }
  }
}
