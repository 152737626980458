.LiveChatBenefits-main-container {
  padding: 110px 10px;
  gap: 40px;
  display: flex;
  flex-direction: column;
  h2 {
    color: #fff;
    text-align: center;
    font-family: "Open Sans";
    font-size: 40.195px;
    font-style: normal;
    font-weight: 700;
    line-height: 58.8px;
    text-align: center;
  }

  .LiveChatBenefits-centered-container {
    width: 60%;
    margin: auto;
  }
}
@media only screen and (max-width: 1500px) {
  .LiveChatBenefits-main-container {
    .LiveChatBenefits-centered-container {
      width: 80%;
    }
  }
}
@media only screen and (max-width: 700px) {
  .LiveChatBenefits-main-container {
    padding: 40px 20px;

    h2 {
      font-size: 32px;
    }

    .LiveChatBenefits-centered-container {
      width: 100%;
    }
  }
}
