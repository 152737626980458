.trustedContainer {
  background-color: black;
  padding: 20px;
  // border: 2px solid red;
  padding-top: 100px;

  h2 {
    width: 20%;
    margin: 0 auto;
    color: #fff;
    text-align: center;
    font-family: "Plus Jakarta Sans";
    font-size: 57.133px;
    font-style: normal;
    font-weight: 800;
    line-height: 66.655px;
    /* 116.667% */


    span {
      color: var(--primary-light, #7d5ba6);
    }
  }

  .brandsName {
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 100px;
    gap: 20px;
    flex-wrap: wrap;
  }
}

@media screen and (max-width:1440px) {

  .trustedContainer {
    h2 {
      font-size: 47px;
      width: 100%;
    }
  }
}

@media screen and (max-width:600px) {
  .trustedContainer {
    padding-top: 20px;

    h2 {
      font-size: 25px;
    }

    .brandsName {
      margin-top: 0;
      padding-bottom: 0;
    }
  }

}