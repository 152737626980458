.businessBenefits-inner-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 20px 32px 20px;
  border-radius: 2000px 2000px 0px 0px;
  background: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
  max-width: 350px;

  img {
  }

  h5 {
    color: #212529;
    text-align: center;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 51px 0px 59px 0px;
  }

  p {
    color: #000;
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.9px;
  }
}
