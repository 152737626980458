
.Analytics-main-container {
  color: #000;
  padding: 63px;
  position: relative; /* Added to position the spinner centrally */
  min-height: 100vh; /* Ensure the container takes up the full viewport height */
  justify-content: center !important;
    align-items: center !important;
 

  .Analytics-inner-container {
    width: 100%;
    max-width: 1400px;
    margin: auto;
    border-radius: 20px;
    background: var(--white);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
    padding: 30px 40px;
    justify-content: center !important;
    align-items: center !important;
 
  


    h5 {
      color: var(--black);
      text-overflow: ellipsis;
      font-family: "Open Sans";
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .common-heading {
      margin-top: 60px;
    }

    .Analytics-stats-container {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      margin-top: 30px;
      gap: 30px;

      .Analytics-stats-box {
        padding: 22px;
        border-radius: 29.699px;
        box-shadow: var(--shadow-1);

        .progresss-bar {
          position: relative;

          .percentage {
            position: relative;
            z-index: 5;
          }
        }

        h6 {
          margin-bottom: 20px;
          color: #696969;
          font-family: "Open Sans";
          font-size: 18.447px;
          font-style: normal;
          font-weight: 400;
        }

        h3 {
          color: var(--text3);
          text-align: right;
          font-family: "Open Sans";
          font-size: 30px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          span {
            color: var(--text3);
            font-family: "Open Sans";
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }

        .timePeried-div {
          h4 {
            color: var(--text2);
            font-family: "Open Sans";
            font-size: 18.447px;
            font-weight: 400;
            width: 100%;
            white-space: nowrap;
          }

          p {
            color: var(--primary-light, #7d5ba6);
            font-family: "Open Sans";
            font-size: 20px;
            font-weight: 400;

            span {
              color: var(--text3);
              font-family: "Open Sans";
              font-size: 12px;
              font-weight: 600;
            }
          }
        }
      }
    }

    .Analytics-askedQuestion-container {
      .Analytics-askedQuestion-box {
        width: 100%;
        max-width: 872px;
        padding: 18px 24px;
        margin-top: 20px;
        color: #16122b;
        font-family: "Open Sans";
        font-size: 20px;
        font-weight: 400;
        border-radius: 29.699px;
        background: var(--white);
        box-shadow: var(--shadow-1);
        cursor: pointer;
      }
    }
  }

  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 3px solid rgba(195, 195, 195, 0.6);
    border-radius: 50%;
    border-top-color: rgb(168, 144, 211);
    animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
}

@media only screen and (max-width: 1650px) {
  .Analytics-main-container {
    .Analytics-inner-container {
      .Analytics-stats-container {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}
@media only screen and (max-width: 1440px) {
  .Analytics-main-container {
    .Analytics-inner-container {
      .Analytics-stats-container {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

@media only screen and (max-width: 1250px) {
  .Analytics-main-container {
    .Analytics-inner-container {
      .Analytics-stats-container {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .Analytics-main-container {
    padding: 20px;

    .Analytics-inner-container {
      padding: 20px;

      .Analytics-stats-container {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .Analytics-main-container {
    padding: 20px 10px;

    .Analytics-inner-container {
      .Analytics-stats-container {
        grid-template-columns: repeat(1, 1fr);
      }

      .Analytics-askedQuestion-container {
        .Analytics-askedQuestion-box {
          font-size: 16px;
        }
      }
    }
  }
}
