.Subscription-card-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 30px 15px 30px;
    height: 550px;
    border-radius: 17.378px;
    border: 1.596px solid rgba(255, 255, 255, 0.10);
    background: var(--tabele-head-bg);
    backdrop-filter: blur(119.67300415039062px);

    h3 {
        color: #000;
        font-family: "Open Sans";
        font-size: 27.805px;
        font-weight: 700;
        margin-bottom: 25px;
    }

    h6 {
        color: var(--text1);
        font-family: "Open Sans";
        font-size: 19.116px;
        font-weight: 400;
        margin-bottom: 18px;
    }

    ul {
        li {
            color: var(--text1);
            font-family: "Open Sans";
            font-size: 15.64px;
            font-weight: 400;
            margin-bottom: 15px;

            img {}
        }
    }

    h5 {
        color: var(--text1);
        font-family: "Open Sans";
        font-size: 27.805px;
        font-weight: 700;
        margin-top: 45px;

        span {
            color: var(--text1);
            font-family: "Open Sans";
            font-size: 15.64px;
            font-weight: 400;
        }
    }
}




.payment-details {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    
    h5 {
      font-size: 18px;
      margin-bottom: 10px;
      color: #333;
    }
  
    p {
      font-size: 16px;
      margin: 8px 0;
      color: #555;
  
      &:last-child {
        margin-bottom: 0;
      }
    }
  
    .card-element {
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 6px;
      margin-bottom: 20px;
      background-color: #fff;
    }
    
    .amount-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
    }
   
    button.themeGlow-btn {
      margin-top: 20px;
      background-color: #007bff;
      color: white;
      font-size: 18px;
      padding: 12px 20px;
      border-radius: 6px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    
      &:hover {
        background-color: #0056b3;
      }
    
      &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
      }
    }
  }
  
  

@media only screen and (max-width: 650px) {
    .Subscription-card-box {
        padding: 30px 30px 15px 30px;
        height: 450px;
    }
}