.KeyFeatures-container {
    background-image: url('../../../../../src/images/Pattern.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    .KeyFeatures-centered-container {
        width: 75%;
        margin: auto;

        h4 {
            color: var(--black);
            font-family: "Open Sans";
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 58px;
            text-align: center;
        }

        h3 {
            color: #000;
            font-family: "Open Sans";
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            text-align: center;
            line-height: 58px;
            margin-top: 10px;
        }

        .KeyFeatures-inner-container {
            background-color: var(--white);
            border-radius: 20px;
            box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
            padding: 70px 40px ;
            margin: 70px 0px 153px 0px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 35px;

            .KeyFeatures-inner-box {
                position: relative;
                padding: 30px;
                text-align: center;
                border-radius: 20px;
                border-radius: 10px;
                border: 2px solid #F4F4F4;

                &::after {
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 18px;
                    border-radius: 10px 10px 0px 0px;
                    background: var(--primary-light-1, #A890D3);
                    top: 0;
                    left: 0;
                    right: unset;
                    bottom: unset;
                }

                h5 {
                    color: var(--primary-dark, #4B2E83);
                    font-family: "Open Sans";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 58px;
                }

                p {
                    color: #4E4E4E;
                    text-align: center;
                    font-family: "Open Sans";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 160%;
                }
            }

        }
    }
}


@media screen and (max-width: 1400px) {
    .KeyFeatures-container {
        .KeyFeatures-centered-container {
            width: 95%;

            // border: 2px solid red;
            .KeyFeatures-inner-container {
                .KeyFeatures-inner-box {
                    h5 {
                        line-height: normal;
                        margin-bottom: 15px;
                    }

                    p {
                        line-height: normal
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .KeyFeatures-container {
        .KeyFeatures-centered-container {
            .KeyFeatures-inner-container {
                gap: 10px;
            }
            // width: 100%;
        }
    }
}


@media screen and (max-width: 900px) {
    .KeyFeatures-container {
        .KeyFeatures-centered-container {
            .KeyFeatures-inner-container {
                padding: 20px;
                margin: 50px 0px 90px 0px;
                grid-template-columns: repeat(1, 1fr);
                gap: 15px;

                .KeyFeatures-inner-box {
                    padding: 30px 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .KeyFeatures-container {
        .KeyFeatures-centered-container {
            h4 {
                font-size: 18px;
                line-height: normal;
            }

            h3 {
                font-size: 20px;
                line-height: normal;
            }

            .KeyFeatures-inner-container {
                padding: 10px;

                .KeyFeatures-inner-box {
                    h5 {
                        font-size: 18px;
                        line-height: normal;
                        margin-bottom: 15px;
                    }

                    p {
                        font-size: 14px;
                        line-height: normal
                    }
                }
            }
        }
    }
}