.cardsContainer {
  padding: 20px;
  width: 70%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
}

@media screen and (max-width: 1550px) {
  .cardsContainer {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .cardsContainer {
    padding: 0px;
    padding-top: 30px;
  }
}
