.connect-ai-container {
  background-image: url("../../../../../public/assets/images/Integration.png");
  height: fit-content;

  .connect-container-content {
    padding: 200px 70px 100px 70px;
    gap: 51px !important;

    .connect-customer-container {
      gap: 17px;

      .features-btn {
        padding: 11px 40px;
        border-radius: 72px;
        background: var(--primary-light, #7d5ba6);
        color: #fff;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        font-family: Inter;
      }

      h1 {
        height: fit-content;
        text-align: center;
        max-width: 34%;
        color: #fff;
        font-family: "Open Sans";
        font-size: 2.2vw;
        font-style: normal;
        font-weight: 300;
        line-height: 133.2%;
        text-transform: capitalize;

        span {
          font-weight: 700;
        }
      }
    }

    .video-conatiner-content {
      width: 87%;
      margin: auto;

      .right-section {
        img {
          max-width: 100%;
          max-height: 507px;
        }

        .circular-div-list {
          gap: 32px;

          span {
            border-radius: 72px;
            background: #fff;
            padding: 11px 35px;
            width: fit-content;
            color: #303030;
            font-family: "Open Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 160%;
            text-transform: capitalize;
          }
        }
      }
    }

    .text-conatiner-content {
      width: 50%;
      padding: 0px 100px 100px 102px;

      h5 {
        color: var(--primary-light, #7d5ba6);
        font-family: "Open Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 20px;
      }

      h3 {
        color: #fff;
        font-family: "Open Sans";
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
      }
    }

    .bottom-content {
      p {
        color: #fff;
        text-align: center;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        max-width: 907px;
      }

      .bottom-btns {
        .light-btn {
          height: fit-content;
        }
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .connect-ai-container {
    .connect-container-content {
      padding: 100px 40px 70px 40px;

      .connect-customer-container {
        h1 {
          max-width: 39%;
        }
      }

      .video-conatiner-content {
        .left-section {
          .video-sec {
            .absolute-position {
              width: 100px;
              height: 100px;
            }
          }
        }
      }

      .bottom-content {
        p {
          font-size: 18px;
          max-width: 668px !important;
        }

        .light-btn {
          font-size: 16px;
          height: 50px;
        }
      }
    }
  }
  .text-conatiner-content {
    padding: 30px 100px 100px 50px !important;
  }
}

@media only screen and (max-width: 1260px) {
  .connect-ai-container {
    .connect-container-content {
      .video-conatiner-content {
        .left-section {
          max-width: 50%;

          .video-sec {
            .play-video-btn {
              top: 57%;
              right: 7%;
              width: 37%;
              height: 41%;
              background-size: 58%, 58%;
            }
          }
        }

        .right-section {
          img {
            max-height: 407px;
          }

          .circular-div-list {
            gap: 26px;

            span {
              padding: 8px 10px;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .connect-ai-container {
    .connect-container-content {
      .video-conatiner-content {
        flex-direction: column-reverse;

        .text-conatiner-content {
          width: 100%;
          padding: 0px 10px 30px 0px !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .connect-ai-container {
    .connect-container-content {
      padding: 40px 20px;

      .text-conatiner-content {
        width: 100%;
        padding: 0px 10px 20px 0px !important;
        h5 {
          font-size: 18px;
          margin-bottom: 10px;
        }
        h3 {
          font-size: 20px;
        }
      }
      .connect-customer-container {
        .features-btn {
          padding: 7px 30px;
          font-size: 14px;
        }

        h1 {
          max-width: 100%;
          font-size: 24px;
        }
      }

      .video-conatiner-content {
        .left-section {
          max-width: 100%;
          min-height: 40% !important;

          .top-bar {
            height: 50px;
            padding: 5px 15px;

            span {
              li {
                width: 16px;
                height: 16px;
              }
            }

            button {
              font-size: 12px;
              width: 140px;
              height: 30px;

              .play-icon {
                width: 20px;
                height: 20px;
              }
            }
          }

          .video-sec {
            img {
              max-width: 100%;
              max-height: 60% !important;
            }

            .absolute-position {
              width: 70px;
              height: 70px;
            }
          }
        }

        .right-section {
          margin-top: 20px;
          max-width: 100% !important;

          img {
            display: none;
          }

          .circular-div-list {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            gap: 10px;
            width: 100%;

            span {
              padding: 7px 14px;
              font-size: 14px;
            }
          }
        }
      }

      .bottom-content {
        gap: 20px;

        p {
          font-size: 14px;
          width: 100%;
        }

        .bottom-btns {
          flex-wrap: wrap;
          gap: 10px;
          justify-content: center;
          align-items: center;

          .light-btn {
            font-size: 12px;
            padding: 6px 10px;
          }
        }
      }
    }
  }
}
