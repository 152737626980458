.demo-main-container {
  // height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  // border: 1px solid red;

  .demotop {
  }
}

@media only screen and (max-width: 600px) {
  .demo-main-container {
    height: fit-content;

    .demotop {
      padding-top: 40px;
    }
  }
}

@media only screen and (max-height: 700px) {
  .demo-main-container {
    height: fit-content;

    .demotop {
      padding-top: 0px !important;
    }
  }
}