.FetchVoice-div {
    .theme-btn-light {
        font-size: 12px;
        color: #4B2E83;
        padding: 5px 15px;
    }
}

.fetchVoices-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-top: 30px;
}

@media only screen and (max-width: 900px) {
    .fetchVoices-container {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media only screen and (max-width: 650px) {
    .fetchVoices-container {
        grid-template-columns: repeat(1, 1fr);
    }
}

.input-dash {
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 16px;
    width: 100%;
    color:black
  }
  
  .validation-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .country-code {
    color: green;
    font-size: 14px;
    margin-top: 5px;
  }