.agentInfo-main-container {
  .agentInfo-temp-box {
    .agentInfo-tempTxt-box {
      h6 {
        color: var(--black);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        padding-top: 10px;
      }

      p {
        color: var(--text1);
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        letter-spacing: -0.28px;
        padding-top: 5px;
      }
    }

    span {
      display: flex;
      width: fit-content;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      border-radius: 500px;
      margin-top: 8px;
      background: var(--primary-light-1, #a890d3);
      color: var(--primary-dark, #4b2e83);
      text-align: center;
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
  }

  .scheduling-h6 {
    color: var(--black);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.4px;
    margin-top: 30px;
  }
  .underline {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background: #e5e5e5;
      left: 50%;
      bottom: -15px;
      transform: translate(-50%);
    }
  }
  .Agent-details-container {
    margin-top: 22px;

    label {
      color: var(--black);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: -0.14px;
    }

    input {
      margin-top: 10px;
      background-color: var(--light-bg);
      color: var(--text1);
    }

    textarea {
      margin-top: 10px;
      background-color: var(--light-bg);
      color: var(--text1);
    }

    .PhoneInput {
      display: flex;
      gap: 10px;
      padding: 0px 16px;
      align-items: center;
      border-radius: 12px;
      border: none;
      width: 100%;
      margin-top: 10px;
      background: rgba(244, 244, 244, 0.05);

      .PhoneInputCountry {
        .PhoneInputCountrySelect {
          background-color: rgb(98, 98, 98);
        }
      }

      .PhoneInputInput {
        margin-top: 0px;
        background-color: transparent;
        border: none;
        padding: 14px 0px;
      }
    }
  }

  .Agent-usecase-container {
    padding-top: 30px;

    label {
      color: #6f767e;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: -0.14px;
    }

    .Agent-usecase-box {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin-top: 10px;

      .option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 20px;
        cursor: pointer;
        display: flex;
        padding: 16px 20px;
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.1);

        .text {
          margin-right: 10px;
        }

        .radio-button {
          width: 25px;
          height: 25px;
          border: 1px solid #687588;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          &.checked {
            background-color: #7d5ba6;
          }

          .inner-circle {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: transparent;
          }

          &.checked {
            .inner-circle {
              background-color: #fff;
            }
          }
        }
      }
    }
  }

  .hr1 {
    height: 1px;
    width: 100%;
    margin: 30px 0px;
    background-color: var(--light-bg);
  }

  .nextBtn {
    color: var(--primary-dark, #4b2e83);
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.15px;
    display: flex;
    width: 98px;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 500px;
    background: var(--primary-light-1, #a890d3);
  }
}
