.aIAssist-main-container {
  background: var(--black, #000);
  padding: 55px 30px 79px 30px;

  h2 {
    color: #fff;
    text-align: center;
    font-family: "Open Sans";
    font-size: 40.195px;
    font-style: normal;
    font-weight: 700;
    line-height: 58.8px;
  }

  .aIAssist-centered-container {
    width: 60%;
    margin: auto;
  }
}
@media only screen and (max-width: 1500px) {
  .aIAssist-main-container {
    .aIAssist-centered-container {
      width: 80%;
    }
  }
}
@media only screen and (max-width: 600px) {
  .aIAssist-main-container {
    padding: 40px 20px 1px 20px;

    h2 {
      font-size: 24px;
      line-height: 30px;
    }

    .aIAssist-centered-container {
      width: 95%;
    }
  }
}
