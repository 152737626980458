.ByTeam-container {
  padding: 107px 20px;

  .ByTeam-centered-container {
    max-width: 1301px;
    margin: auto;
    padding: 80px 91px 63px 99px;
    border-radius: 16.26px;
    background: linear-gradient(92deg, #7d5ba6 0.13%, #a890d3 99.36%);
    box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.2);

    p {
      color: #fff;
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.32px;
      padding-bottom: 30px;
    }

    .ByTeam-img-container {
      display: flex;
      align-items: center;

      img {
        width: 46px;
        height: 47px;
        margin-right: 20px;
      }

      h5 {
        color: #fff;
        font-family: "Open Sans";
        font-size: 32px;
        font-style: normal;
        margin-right: 10px;
        font-weight: 700;
      }

      h6 {
        color: #fff;
        font-family: "Open Sans";
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.32px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .ByTeam-container {
    padding: 50px 20px;

    .ByTeam-centered-container {
      padding: 25px;
      p {
        font-size: 14px;
      }

      .ByTeam-img-container {
        flex-direction: column;
        img {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }

        h5 {
          font-size: 20px;
        }

        h6 {
          font-size: 14px;
        }
      }
    }
  }
}
