.hero-main-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  // height: 120vh;
  padding: 220px 20px 300px 20px;
  background-image: url("../../../public//assets/images/chatImg2.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 90%;
  background-color: rgb(0, 0, 0);
  position: relative;
  z-index: 2;
  .hero-pattern {
    position: absolute;
    top: 27%;
    right: 6%;
    background-image: url("../../../public/assets/images/heropattern.png");
    background-size: 100% 100%;
    width: 90%;
    height: 674px;
    z-index: -1;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .hero-centered-container {
    display: flex;
    align-items: center;
    justify-content: end;
    margin: auto;
    width: 80%;
    gap: 50px;
    .hero-left-container {
      width: 42%;
      height: fit-content;

      .light-pill {
        display: inline-flex;
        padding: 8px 30px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 72px;
        background: #a890d3;
        color: #4b2e83;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
        text-transform: capitalize;
        margin-bottom: 20px;
      }

      h1 {
        overflow: hidden;
        color: #fff;
        font-family: "Open Sans";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 152%;
        text-transform: capitalize;
        letter-spacing: 0.96px;

        .spanClr-dark {
          font-weight: 700;
        }
      }

      p {
        color: #fff;
        font-family: Inter;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        text-transform: capitalize;
        padding: 31px 0px 38px 0px;
      }
      .themeGlow-btn2 {
        display: inline-flex;
        padding: 28px 38px 29px 38px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 72px;
        background: var(
          --3-color-grad,
          linear-gradient(90deg, #4b2e83 0%, #a890d3 52%, #4b2e83 100%)
        );
        box-shadow: 0px 0px 60px 0px rgba(107, 80, 158, 0.5);
        color: #fff;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        text-transform: uppercase;
        margin-top: 20px;
      }
    }

    .hero-right-container {
      display: flex;
      justify-content: end;
      width: 50%;
      height: fit-content;
      img {
        position: relative;
        z-index: 2;
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .hero-main-container {
    height: auto;
    .hero-centered-container {
      .hero-left-container {
        h1 {
          font-size: 36px;
        }
        p {
          font-size: 20px;
        }
        .themeGlow-btn2 {
          font-size: 14px;
          padding: 15px 20px;
        }
      }
    }
    .hero-pattern {
      top: 33%;
      height: 500px;

      img {
        width: 63.901px;
        height: 70.031px;
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .hero-main-container {
    height: auto;
    .hero-pattern {
      height: 396px !important;
    }
  }
  .hero-main-container {
    .hero-centered-container {
      .hero-left-container {
        width: 50%;
        h1 {
          font-size: 30px;
        }
        p {
          font-size: 18px;
        }
        .themeGlow-btn2 {
          margin-top: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .hero-main-container {
    height: fit-content;
    padding: 40px 20px;
    .hero-pattern {
      display: none !important;
    }
    .hero-centered-container {
      padding: 0px 0px;
      flex-direction: column;
      width: 100%;

      .hero-left-container {
        width: 100%;

        h1 {
          font-size: 25px !important;
          width: 100%;
        }

        p {
          font-size: 15px;
          padding: 20px 0 20px 0px;
        }
      }

      .hero-right-container {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-height: 700px) {
  .hero-main-container {
    // height: 120vh;
    padding: 50px 20px 50px 20px !important;
    gap: 40px;

    .hero-centered-container {
      margin-top: 120px !important;
    }
  }
}

@media only screen and (min-height: 700px) {
  .hero-centered-container {
    height: 100% !important;
  }
}

@media screen and (max-height: 700px) and (max-width: 600px) {
  .hero-main-container {
    // height: 120vh;
    padding: 50px 20px 50px 20px !important;

    .hero-centered-container {
      margin-top: 0px !important;
      .hero-left-container {
        h1 {
        }
        .themeGlow-btn2 {
          //font-size: 13px;
        }
      }
    }
  }
}
