
    .fetchVoices-box {
        border-radius: 4.863px;
        border: 0.608px solid #E5E5E5;
        background: var(--tabele-head-bg);
        color: var(--black);
        padding: 10px;

        .fetchVoices-username-box {
            h5 {
                color: var(--black);
                font-family: "Open Sans";
                font-size: 16px;
                font-weight: 500;
            }

            p {
                color: var(--black);
                font-family: "Open Sans";
                font-size: 12px;
                font-weight: 500;
                opacity: 0.8;
            }
        }

        audio{
            width: 100%;
        }

        .fetchVoices-userInfo-box {
            span {
                h6 {
                    color: var(--black);
                    font-family: "Open Sans";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                }
            }
        }

        .fetchVoices-select-box {
            h6 {
                color: var(--text1);
                font-family: "Open Sans";
                font-size: 12px;
                font-weight: 400;
            }

            p {
                color: var(--text1);
                font-family: "Open Sans";
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
