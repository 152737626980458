.CreateAgentBrief-main-container {
  .CreateAgentBrief-centered-container {
    width: 100%;
    max-width: 950px;
    margin: auto;
    padding: 40px 15px 70px 15px;

    h5 {
      color: var(--black);
      font-family: "Open Sans";
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .CreateAgentBrief-inner-container {
      width: 100%;
      padding: 30px 24px 24px 24px;
      border-radius: 20px;
      background: rgba(252, 252, 252, 0.05);
      box-shadow: var(--shadow-1);
      margin-top: 20px;

      .nav-switch {
        // padding: 2px;
        display: flex;
        border-radius: 500px;
        width: auto;
        border: 1px solid #a890d3;

        button {
          padding: 16px 2px;
          width: 100%;
          color: #a890d3;
          font-family: "Open Sans";
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          text-align: center;
        }

        .active {
          border-radius: 500px;
          background: var(--primary-light-1, #a890d3);
          color: var(--primary-dark, #4b2e83);
          font-weight: 600;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .CreateAgentBrief-main-container {
    .CreateAgentBrief-centered-container {
      .CreateAgentBrief-inner-container {
        .nav-switch {
          flex-wrap: wrap;
          border-radius: 10px !important;
          padding: 10px 20px;
          button {
            padding: 10px 5px !important;
            font-size: 14px !important;
            font-weight: 700 !important;
            border-radius: 10px !important;
          }
        }
      }
    }
  }
  .CreateAgentBrief-main-container
    .CreateAgentBrief-centered-container
    .CreateAgentBrief-inner-container {
    padding: 20px;
  }
}
@media only screen and (max-width: 600px) {
  .CreateAgentBrief-centered-container {
    .CreateAgentBrief-centered-container {
      h5 {
        font-size: 18px;
      }
    }
  }
}
