.Script-main-container {

    h5 {
        color: #FFF;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: -0.4px;
        padding: 30px 0px;
    }

    .Agent-details-container {
        margin-top: 22px;

        label {
            color: var(--black);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            letter-spacing: -0.14px;
        }

        input {
            margin-top: 10px;
            background-color: var(--light-bg);
            color: var(--text1);
        }

        textarea {
            margin-top: 10px;
            background-color: var(--light-bg);
            color: var(--text1);
        }

        .PhoneInput {
            display: flex;
            gap: 10px;
            padding: 0px 16px;
            align-items: center;
            border-radius: 12px;
            border: none;
            width: 100%;
            margin-top: 10px;
            background: rgba(244, 244, 244, 0.05);

            .PhoneInputCountry {
                .PhoneInputCountrySelect {
                    background-color: rgb(98, 98, 98);
                }
            }

            .PhoneInputInput {
                margin-top: 0px;
                background-color: transparent;
                border: none;
                padding: 14px 0px;
            }
        }
    }

    .theme-btn {
        width: 100%;
    }
}