/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap") */

body {
  scroll-behavior: smooth;
}
.b-red {
  border: 1px solid red;
}

.grenish {
  color: #00a71b !important;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
}

.light-color {
  color: #343434;
}

.black {
  background-color: #000;
}

.primaryClrLight {
  color: #7d5ba6 !important;
}

.primaryClrLightBorder {
  border: 2px solid #7d5ba6 !important;
}

.spanClr-dark {
  color: #7d5ba6;
  font-weight: 500;
}

.purple {
  color: #a890d3 !important;
}

.primaryClr {
  color: #4b2e83;
}

.spanClr-light {
  color: #a890d3;
  font-weight: 500;
}

.themeGlow-btn {
  color: var(--white);
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  display: inline-flex;
  padding: 15px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 72px;
  background: var(--primary-gradient-bg);
  cursor: pointer;
}

.topminus {
  margin-top: -150px !important;
}

.h-center {
  display: flex;
  justify-content: center;
}

.v-center {
  display: flex;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.cursor-pointer {
  cursor: pointer;
}

.d-flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.w-100 {
  width: 100%;
}

.absolute-position {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.light-btn {
  color: var(--primary-dark, #4b2e83);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  text-transform: uppercase;
  padding: 12px 15px 13px 15px;
  border-radius: 44px;
  background: #a890d3;
  height: 54px;
  width: fit-content;
}

.gap-80 {
  gap: 80px;
}

.heading-4 {
  color: #000;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.heading-5 {
  color: #000;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: "Open Sans";
  font-size: 17.247px;
  font-weight: 400;
}

.heading-small {
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  color: #000;
}

.span-txt {
  color: #949498;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  font-family: "Open Sans";
}

.purple-btn {
  border-radius: 500px;
  background: #4b2e83;
  color: #fff;
}

.light-black-btn {
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.1);
}
.para-med {
  overflow: hidden;
  color: var(--text1);
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  text-overflow: ellipsis;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.darkerrrr {
  font-weight: 700 !important;
}
/* 
input:focus {
  outline: 1px solid rgba(236, 39, 39, 0.05) !important;
} */
.input-dash {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  border-radius: 12px;
  border: none;
  width: 100%;
  background: rgba(244, 244, 244, 0.05);
}

/* 
.input-dash:focus {
  outline: 1px solid rgba(244, 244, 244, 0.05) !important;
} */

.theme-btn-tran {
  display: flex;
  width: fit-content;
  color: var(--black);
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  border-radius: 500px;
  border: 2px solid #4b2e83;
  white-space: nowrap;
}

.theme-btn {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  background: var(--primary-dark, #4b2e83);
  width: fit-content;
  white-space: nowrap;
}

.theme-btn-light {
  display: flex;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  background: var(--primary-light-1, #a890d3);
  width: fit-content;
  color: var(--black);
  font-family: "Open Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.theme-btn-light-pill {
  display: flex;
  padding: 8px 18px;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  background: var(--primary-light-1, #a890d3);
  width: fit-content;
  color: var(--primary-dark, #4b2e83);
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.15px;
}

.theme-btn-simple {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  border-radius: 500px;
  border: 1px solid var(--primary-dark, #4b2e83);
  width: fit-content;
  color: #949498;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.light-border {
  border-radius: 11.182px;
  border: 0.932px solid #2c2c33;
  background: var(--black-1, #333);
}

.dark-pill {
  color: #fff;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  padding: 8px 12px;
  align-items: center;
  border-radius: 500px;
  background: var(--primary-dark, #4b2e83);
}

.received-txt {
  color: #0e0e0e;
  font-family: "Open Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 158.5%;
  padding: 10px 15px;
  border-radius: 0px 12.69px 12.69px 12.69px;
  background: #e2e2e2;
  max-width: 230px;
  margin-top: 8px;
}

.sender-txt {
  color: #fff;
  font-family: "Open Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 158.5%;
  padding: 10px 15px;
  border-radius: 12.689px 12.69px 0px 12.69px;
  background: var(--primary-light-1, #4B2E83);
  max-width: 260px;
  margin-top: 8px;
}

.ml-8 {
  margin-left: 30px;
}

.hr2 {
  width: 100%;
  height: 1px;
  border-radius: 1px;
  background: #e5e5e5;
}

/* Label */
.custom-checkbox input[type="checkbox"] {
  display: none;
}

/* Style the custom checkbox */
.custom-checkbox label {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-right: 10px;
  cursor: pointer;
}

/* Create the checkmark for the custom checkbox */
.custom-checkbox label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #4b2e83;
  border-radius: 3px;
}

/* Show the checkmark when the checkbox is checked */
.custom-checkbox input[type="checkbox"]:checked + label:before {
  background-color: #4b2e83;
  border-color: #4b2e83;
}

/* Style the checkmark inside the custom checkbox */
.custom-checkbox label:after {
  display: flex;
  justify-content: center;
  align-items: center;
  content: "\2713";
  position: absolute;
  top: -1px;
  left: 5px;
  font-size: 14px;
  color: white;
  visibility: hidden;
}

/* Show the checkmark when the checkbox is checked */
.custom-checkbox input[type="checkbox"]:checked + label:after {
  visibility: visible;
}

/* Label */

.dashh-input {
  display: flex;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  gap: 12.471px;
  margin: 10px 0px;
  width: 100%;
  border-radius: 9.977px;
  border: 1.247px solid var(--light-bg);

  &:focus {
    border: 1.247px solid var(--light-bg);
  }
}

.b-lightwhite {
  border-bottom: 1px solid red !important;
}

.back-btn {
  display: flex;
  width: 150px;
  padding: 14px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 500px;
  background: #474747;
  color: #fff;
  text-align: center;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
