.AIHero-main-container {
  position: relative;
  height: 120vh;
  background-image: url("../../../../public//assets/images/aiAgenthero.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .AIHero-shadow-container {
    opacity: 0.5;
    background-color: #000;
    height: 120vh;
    width: 100%;
  }

  .AIHero-text-centered-container {
    position: absolute;
    width: 80%;

    h1 {
      color: #fff;
      font-family: "Open Sans";
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      // line-height: 58px;
    }

    p {
      color: #fff;
      font-family: "Open Sans";
      font-size: 18.438px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
      max-width: 622px;
      margin-top: 20px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .AIHero-main-container {
    height: 120vh;
  }
}
@media only screen and (max-width: 600px) {
  // .AIHero-main-container {
  //   height: 80vh;

  //   .AIHero-shadow-container {
  //     height: 80vh;
  //   }
  // }
  .AIHero-main-container {
    height: 70vh;

    .AIHero-shadow-container {
      height: 70vh;
    }

    .AIHero-text-centered-container {
      padding: 0px 10px;
      width: 100%;
      h1 {
        font-size: 40px;
        text-align: center;
      }

      p {
        font-size: 15px;
        text-align: center;
        line-height: 30px;
      }
    }
  }
}
