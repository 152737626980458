.LoaderFaqs-container {
  h5 {
    color: var(--black);
    font-family: "Open Sans";
    font-size: 29.931px;
    font-weight: 600;
    text-align: left;
  }

  .KnowledgeLibraryTab-search-box {
    display: flex;
    width: 100%;
    max-width: 580px;
    align-items: center;
    gap: 8px;
    border-radius: 500px;
    border: 1px solid #dcdcdc;
    background: var(--white);

    .search-icon {
      font-size: 22px;
      margin-right: 18px;
    }

    input {
      padding: 10px 20px;
      border-radius: 500px;
      width: 100%;
      border: none;
      color: #6e6e70;
    }
  }

  .theme-btn-tran {
    padding: 10px 20px;
  }

  .new-bg {
    padding: 10px 20px !important;
    background: #474747;
    font-size: 21.269px;
    margin-top: 20px;
  }
}

.loaderFaq-box {
  width: 100%;

  .tags-div {
    width: 100%;
    padding: 15px 20px;
    border-radius: 9.27px;
    margin-top: 5px;
    margin-bottom: 5px;
    background: var(--white);
    width: 100%;
    border: none;
  }

  label {
    color: #000;
    font-family: "Open Sans";
    font-size: 18.541px;
    font-weight: 500;
  }

  input {
    width: 100%;
    padding: 15px 20px;
    border-radius: 9.27px;
    margin-top: 5px;
    margin-bottom: 5px;
    background: var(--white);
    width: 100%;
    border: none;

    &::placeholder {
      opacity: 0.5;
    }
  }

  textarea {
    padding: 12px;
    width: 100%;
    margin-top: 5px;
    border-radius: 9.27px;
    background: var(--white);
    border: none;
    resize: none;

    &::placeholder {
      opacity: 0.5;
    }
  }
}

@media only screen and (max-width: 650px) {
  .loaderFaq-box {
    label {
      font-size: 16px;
    }
    p {
      font-size: 13px;
    }
  }
  .flexCol {
    flex-direction: column;

    .KnowledgeLibraryTab-search-box {
    }

    .widthFull {
      width: 100%;

      .theme-btn-tran {
        width: 100%;
      }
    }
  }
}
