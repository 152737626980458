.benefits-main-container {
  padding: 161px 20px 170px 20px;
  position: relative;
  overflow: hidden;

  .benefits-centered-container {
    width: 75%;
    margin: auto;
    // background-image: url("../../../../../public//assets//images/Pattern.png");
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: 100% 100%;
    // padding: 0px 0px 75px 0px;
    // .benefit-inner {
    //   gap: 141px;
    // }
    .benefit-inner {
      gap: 141px;
    }
    h2 {
      color: var(--primary-dark, #4b2e83);
      text-align: center;
      font-family: "Open Sans";
      font-size: 60px;
      font-style: normal;
      font-weight: 700;
      line-height: 133.2%;
      text-transform: capitalize;
      padding-bottom: 80px;
    }

    .benefits-leftSilder-box {
      width: 63%;
      justify-content: center;
      display: flex;

      .slick-slider {
        width: 100%;
        box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.2);
        background: transparent !important;
        border-radius: 16px 0px;
        .slick-list {
          background: transparent !important;
          border-radius: 16px 0px;
          .slick-track {
            display: flex;
            gap: 10px;
            // padding: 30px 30px 30px 10px;

            .testemonials-card-box {
              padding: 50px;
              border-radius: 16px 0px;
              background: #fff;
              box-shadow: 0px 16px 40px 0px rgba(41, 41, 41, 0.027);
              margin: 10px;

              img {
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }

    .benefits-rightTxt-box {
      width: 37%;
      padding: 50px;

      h3 {
        color: var(--primary-dark, #4b2e83);
        font-family: "Open Sans";
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 133.2%;
        text-align: left;
        max-width: 274px;
      }

      h5 {
        text-align: left;
        color: var(--primary-dark, #4b2e83);
        font-family: "Open Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 133.2%;
        text-transform: capitalize;
        margin-bottom: 20px;
      }

      .prev-btn {
        position: relative;
        background-image: linear-gradient(90deg, #a890d3 0%, #4b2e83 100%);
        padding: 18px;
        border-radius: 50px;
      }

      .next-btn {
        position: relative;
        background-image: linear-gradient(90deg, #a890d3 0%, #4b2e83 100%);
        padding: 18px;
        border-radius: 50px;
        transform: rotate(180deg);
      }
    }

    .benefits-card-container {
      // width: 80%;
      display: grid;
      // grid-template-columns: repeat(4, 1fr);
      // gap: 40px;
      margin: auto;

      .benefits-card-box {
        display: flex;
        align-items: center;
        flex-direction: column;

        h4 {
          color: var(--primary-dark, #4b2e83);
          text-align: center;
          font-family: "Open Sans";
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          text-transform: capitalize;
          padding-bottom: 20px;
        }

        img {
        }

        p {
          color: var(--black, #000);
          text-align: center;
          font-family: "Open Sans";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          text-transform: capitalize;
          padding-top: 20px;
        }
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    background: radial-gradient(
      49.55% 45.34% at 50% 0%,
      rgba(168, 144, 211, 0.8) 0%,
      rgba(75, 46, 131, 0.8) 0.01%,
      rgba(255, 255, 255, 0) 100%
    );
    width: 100%;
    height: 24px;
    top: 0;
  }
}

.slick-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto !important;
  // border: 2px solid rgb(192, 25, 25);
  // width: 80%;
  gap: 20px !important;
}

.testi-container {
  margin: 0 auto !important;
  //   display: inline-grid;
  // place-content: center;
  //place-items: center;
  // display: flex;
  // align-items: center;
  // justify-content: space-around;
  //border: 2px solid rgb(56, 92, 63);
  // display: grid !important;
  // grid-template-columns: repeat(3, 1fr);
  align-items: center;
  height: 300px;
  justify-content: center;
  padding: 10px;
}

.slick-arrow {
  display: none !important;
}

@media screen and (max-width: 1860px) {
  .slick-slider {
    width: 100%;
  }

  .testi-container {
    display: flex !important;
    place-content: center;
    place-items: center;

    margin: 0 auto;
  }
}
@media screen and (max-width: 1640px) {
  .benefit-inner {
    gap: 91px !important;
  }
}
@media screen and (max-width: 1440px) {
  .benefits-main-container {
    padding: 60px 20px 100px 20px;

    .benefits-centered-container {
      width: 100%;

      h2 {
        padding-bottom: 0px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .benefits-main-container {
    padding: 40px 0px;

    .benefits-centered-container {
      width: 100%;

      .benefits-leftSilder-box {
        width: 50%;
      }

      .benefits-rightTxt-box {
        width: 50%;
        padding: 20px;
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .benefits-main-container {
    .benefits-centered-container {
      .setFlex {
        flex-direction: column;
        gap: 0px;

        .benefits-leftSilder-box {
          width: 100%;
          order: 2;
          flex-direction: column;
          .arrow-slidermobil-btns {
            display: flex;
            justify-content: center;
            align-items: center;
            .prev-btn {
              position: relative;
              background-image: linear-gradient(
                90deg,
                #a890d3 0%,
                #4b2e83 100%
              );
              padding: 18px;
              border-radius: 50px;
            }

            .next-btn {
              position: relative;
              background-image: linear-gradient(
                90deg,
                #a890d3 0%,
                #4b2e83 100%
              );
              padding: 18px;
              border-radius: 50px;
              transform: rotate(180deg);
            }
          }
          .slick-slider .slick-list .slick-track {
            padding: 0px 30px 0px 10px;
          }
        }

        .benefits-rightTxt-box {
          width: 100%;
          order: 1;
          h5 {
            text-align: center;
          }
          .arrow-slider-btns {
            display: none;
          }
        }
      }
    }
  }
  .benefit-inner {
    gap: 20px !important;
  }
}

@media only screen and (max-width: 600px) {
  .benefits-main-container {
    padding: 40px 20px;

    .benefits-centered-container {
      width: 100%;
      background-image: none;
      padding-bottom: 0;

      h2 {
        padding-bottom: 0px;
        font-size: 24px;
      }

      .benefits-card-container {
        grid-template-columns: repeat(1, 1fr);

        .benefits-card-box {
          h4 {
            font-size: 16px;
          }

          p {
            font-size: 14px;
            font-weight: 400;
          }
        }
      }

      .benefits-leftSilder-box {
        .slick-slider {
          .slick-list {
            .slick-track {
              .testemonials-card-box {
                padding: 20px !important;
                margin: -5px;

                img {
                  margin-bottom: 20px;
                }
              }
            }
          }
        }
      }
    }
  }

  .testi-container {
    height: 360px !important;
  }

  // .slick-arrow.slick-prev {
  //   width: 40px;
  //   height: 40px;
  //   border-radius: 50px;
  //   top: 364px;
  //   left: 24%;

  //   &::before {
  //     top: 9px;
  //     left: 8px;
  //   }
  // }

  // .slick-arrow.slick-next {
  //   width: 40px;
  //   height: 40px;
  //   border-radius: 50px;
  //   top: 364px;
  //   right: 24%;

  //   &::before {
  //     top: 7px;
  //     left: 9px;
  //   }
  // }
}
