.Fabicare-RequestDemo {
  background-color: #fff;
}

.demoBtn {
  border-radius: 30px;
  background: var(--primary-light, #7d5ba6);
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 35px;
  position: relative;
  margin-top: 24px;

  // &::before {
  //   content: url("../../../src/images/playaBtn.svg");
  //   position: absolute;
  //   left: 31px;
  //   top: 6px;
  // }
}

.line2 {
  max-width: 1095px;
  height: 1.022px;
  margin: auto;
  background: var(--primary-light, #7d5ba6);
  opacity: 0.5;
  margin-top: 30px;
}

.effortlessCustomerWrapper {
  padding-bottom: 50px;
  width: 74%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: auto;

  h5 {
    color: #FFF;
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 20px;
    line-height: 43px;
  }

  h3 {
    color: #FFF;
    font-family: "Open Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
  }

  .imgDiv {
    width: 50%;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .purpleShade {
    width: 1401px;
    height: 34px;
    background: radial-gradient(49.55% 45.34% at 50% 0%,
        rgba(168, 144, 211, 0.8) 0%,
        rgba(75, 46, 131, 0.8) 0.01%,
        rgba(255, 255, 255, 0) 100%);
    border: 2px solid green;
  }
}

@media screen and (max-width: 1440px) {
  .effortlessCustomerWrapper h3 {
    font-size: 36px;
  }
}

@media screen and (max-width: 1200px) {
  .effortlessCustomerWrapper h3 {
    font-size: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .effortlessCustomerWrapper {
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
}

@media only screen and (max-width: 600px) {
  .Fabicare-RequestDemo {
    padding-bottom: 0px;
  }

  .effortlessCustomerWrapper {
    padding-top: 20px;
    gap: 25px;
    padding-bottom: 0px;
    width: 90%;

    h5 {
      font-size: 18px;
      padding-bottom: 20px;
      line-height: normal;
    }

    h3 {
      font-size: 20px;
      line-height: normal;
      width: 100%;
    }

    .left-section {
      max-width: 100%;

      .video-sec {
        .play-video-btn {
          top: 57%;
          right: 7%;
          width: 37%;
          height: 41%;
          background-size: 58%, 58%;
        }
      }
    }
  }
}