.react-flow .react-flow__handle {
  width: 50px;
  height: 14px;
  border-radius: 3px;
  background-color: #784be8;
}

.react-flow .react-flow__handle-top {
  top: -10px;
}

.react-flow .react-flow__handle-bottom {
  bottom: -10px;
}

.react-flow .react-flow__node {
  /* height: 40px; */
  /* width: 150px; */
  justify-content: center;
  align-items: center;
  display: flex;
  border-width: 2px;
  font-weight: 700;
}

.react-flow .react-flow__edge path,
.react-flow__connectionline path {
  stroke-width: 2;
}

.wrapper {
  flex-grow: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-flow {
  margin-top: 50px;
  border-radius: 20px;
  background: var(--Local-Variable, #fff);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  max-width: 1300px !important;
  width: 100%;
  height: 700px !important;
}



/* custom.css */
.control-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.control-button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px;
}

.control-button:hover {
  background-color: #45a049;
}
