.businessBenefits-main-container {
  background-color: #fff;
  padding: 84px 10px 96px 10px;
  position: relative;
  overflow: hidden;
  gap: 151px;
  h2 {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 40.523px;
    font-style: normal;
    font-weight: 700;
    line-height: 58.8px;
  }

  .businessBenefits-centered-container {
    width: 60%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 46px;
  }
  &::before {
    content: "";
    position: absolute;
    background: radial-gradient(
      49.55% 45.34% at 50% 0%,
      rgba(168, 144, 211, 0.8) 0%,
      rgba(75, 46, 131, 0.8) 0.01%,
      rgba(255, 255, 255, 0) 100%
    );
    width: 100%;
    height: 24px;
    top: 0;
  }
}
@media only screen and (max-width: 1500px) {
  .businessBenefits-centered-container {
    width: 85% !important;
  }
}
// @media only screen and (max-width: 1024px) {
//   .businessBenefits-centered-container {
//     grid-template-columns: repeat(2, 1fr) !important;
//   }
// }

@media only screen and (max-width: 700px) {
  .businessBenefits-main-container {
    padding: 40px 20px 70px 20px;
    gap: 20px;

    h2 {
      // font-size: 30px;
      font-size: 24px;
      margin-bottom: 0px;
    }

    .businessBenefits-centered-container {
      width: 95%;
      grid-template-columns: repeat(1, 1fr) !important;
    }
  }
}
