.KnowledgeLibrarySet-box {
  padding: 0px 60px 40px 105px;

  .KnowledgeLibrary-main-container {
    border-radius: 18.824px;
    padding: 24px;
    margin-top: 30px;
    box-shadow: var(--shadow-1);

    .KnowledgeLibrary-rightBtns-box {
      display: flex;
      justify-content: end;
      gap: 10px;

      h4 {
        font-family: "Open Sans";
        font-size: 28.235px;
        font-weight: 600;
      }
    }

    .KnowledgeLibrary-PrevNextBtns-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;

      .theme-btn {
        color: #6f767e;
        font-family: "Open Sans";
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.15px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .KnowledgeLibrarySet-box {
    padding: 60px 20px 60px 20px;
  }
}

@media only screen and (max-width: 650px) {
  .KnowledgeLibrary-rightBtns-box {
    .flexcol {
      flex-direction: column !important;
      justify-content: start;
      align-items: start;
      gap: 15px;
    }
  }
}
