:root {
  --primary-color: #4b2e83;
  --primary-hover-color: #402770;
  --primary-light-color: #a890d3;
  --secondary-color: rgba(168, 144, 211, 0.4);

  --black: #000;
  --white: #fff;

  /* Background Gradient colors */
  --primary-gradient-bg: linear-gradient(90deg, #4b2e83 0%, #a890d3 100%);
  /* Background Gradient colors */

  /* Text Colors */
  --text1: #343434;
  --text2: #696969;
  --text3: #2b2b2b;
  /* Text Colors */

  /* Text Colors */
  --tabele-head-bg: #f8f8f8;
  --light-bg: #e5e5e5;
  /* Text Colors */

  /* Shadow Colors */
  --shadow-1: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
  /* Shadow Colors */

  /* Borders Colors */
  --border-primary: 1.345px solid rgba(168, 144, 211, 0.3);
  /* Borders Colors */

  /* Transitions */
  --tranition-half: 0.5s ease-in-out;
  /* Transitions */
}
.text-Lpurple {
  color: var(--primary-light-color);
}
