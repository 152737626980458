.LiveChat-main-container {
  padding: 22px 63px 14px 100px;
  color: #000;
  .top {
    display: flex;
    flex-direction: column;
    gap: 24px;
    h5 {
      color: var(--black);
      text-overflow: ellipsis;
      font-family: "Open Sans";
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    label {
      overflow: hidden;
      color: #000;
      font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
      text-overflow: ellipsis;
      font-family: "Open Sans";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  // Dropdown
  .bottom {
    .ant-dropdown-trigger {
      .ant-space {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
      }
    }
    .live-chat-dropdown {
      border-radius: 16px;
      background: var(--Local-Variable, #fff);
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
      margin-top: 31px;
      display: flex;
      align-items: center;
      width: 568px;
      height: 57px;
      padding: 10px 10px 10px 23px;
    }
  }
  .dropdown-icon {
    transition: transform 0.3s ease;

    &.open {
      transform: rotate(180deg);
    }
  }

  // .LiveChatDropDown {
  //   margin-top: 20px;
  //   box-shadow: var(--shadow-1);
  //   width: 100%;
  //   max-width: 400px;
  //   justify-content: space-between;
  //   padding: 18px;
  //   color: var(--text1);
  //   font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  //   text-overflow: ellipsis;
  //   font-family: "Open Sans";
  //   font-size: 18px;
  //   font-style: normal;
  //   font-weight: 600;
  //   line-height: normal;
  // }

  // .LiveChatDropDown-box {
  //   width: 100%;
  //   max-width: 400px;

  //   ul {
  //     li {
  //       a {
  //         font-size: 16px;
  //         font-weight: 500;
  //         padding: 15px 20px;
  //         border-bottom: 1px solid #e5e5e5;
  //       }
  //     }
  //   }
  // }
  .ant-dropdown-menu {
    background: none !important;
    box-shadow: none !important;
  }
}
:where(.css-dev-only-do-not-override-1ae8k9u).ant-dropdown {
  border: 1px solid #dcdcdc !important;
  // background: transparent !important;
  border-radius: 16px !important;
}


.ant-dropdown-menu-item {
  padding: 10px 10px 10px 23px !important;
  color: #000 !important;
  font-family: "Open Sans" !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
@media only screen and (max-width: 650px) {
  .LiveChat-main-container {
    padding: 22px 20px 14px 20px;
    color: #000;
    .bottom {
      .live-chat-dropdown {
        width: 100%;
      }
    }
  }
}
.ant-dropdown{
border: none !important;

}