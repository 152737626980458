.import-csv-content {
    margin-top: 20px;
    border-radius: 8px;
    border: 1px dashed rgba(52, 52, 52, 0.40);
    background: #F8F8F8;
    cursor: pointer;

    form {
        padding: 20px;
    }

    label {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    img {
        width: 71.864px;
        height: 71.864px;
    }

    button {
        margin-top: 10px;
    }

    #form-file-upload {
        text-align: center;
        position: relative;
        gap: 25px;
    }

    #input-file-upload {
        display: none;
    }

    #drag-file-element {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
    }
}




.status-icon {
    width: 20px !important;
    height: 20px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #27A376 !important;
}

.status-icon-cancel {
    background-color: rgb(212, 67, 67) !important;
}


.fileName-container {
    width: 100%;

    h6 {
        color: #343434;
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        margin-top: 20px;
    }
}


.progress {
    width: 100%;
    height: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
    background: #FFF;
    overflow: hidden;
    margin-top: 10px;
}




.progress-bar {
    width: 0;
    height: 100%;
    border-radius: 8px;
    background: var(--primary-light-1, #A890D3);
    transition: width 0.3s ease-in-out;
}

.cancel-button {
    color: var(--primary-light-1, #A890D3);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    cursor: pointer;
    margin-top: 10px;
    white-space: nowrap;
}

//////File Progress
.file-progress {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}