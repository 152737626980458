.chooseWrapper {
  background: var(--black-grad, linear-gradient(90deg, #000 0%, #474747 100%));
  padding-top: 130px;
  padding-bottom: 50px;

  .choose-cards-container {
    width: 75%;
    margin: 0 auto;
    padding: 20px;
  //  border: 2px solid green;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
}

@media screen and (max-width: 1300px) {
  .chooseWrapper .choose-cards-container {
    width: 100%;
  }
}

@media screen and (max-width: 1300px) {
  .chooseWrapper .choose-cards-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .chooseWrapper {
    padding-top: 30px;
    .choose-cards-container {
      padding: 0;
      margin-top: 40px;
    }
  }
}
