.enable-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 11px;
    background: var(--tabele-head-bg);
    display: flex;
    padding: 12px 18px;
    align-items: center;
    gap: 152px;
    width: 100%;
    max-width: 400px;
    margin-top: 20px;

    h6 {
        color: var(--text1);
        font-family: "Open Sans";
        font-size: 16px;
        font-weight: 500;
        white-space: nowrap;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #A890D3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #A890D3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

@media only screen and (max-width: 650px) {
    .enable-box {
        gap: 10px;
    }
}