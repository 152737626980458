.roadmap-sec {
  padding: 66px 0px 0px 0px !important;
  .service-slider_wrapper {
    overflow: hidden;
    height: 500px !important;

    .service-slider__item {
      position: relative;
      width: 85%;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      .features {
        display: flex;
        flex-direction: column;
        row-gap: 1px;
      }

      .quarter {
        // height: 500px;

        .enhancing-left-container {
          width: 400px;

          img {
            border-radius: 10px;
            width: 100%;
            height: 250px;
          }
        }

        .enhancing-right-container {
          background-image: linear-gradient(90deg, #a890d3 0%, #4b2e83 100%);
          padding: 110px;
          border-radius: 20px;
          width: calc(100% - 400px);

          h5 {
            color: var(--white);
            text-align: left;
            font-family: "Open Sans";
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 133.2%;
            text-transform: capitalize;
          }

          h3 {
            color: var(--white);
            text-align: left;
            font-family: "Open Sans";
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 133.2%;
            padding: 18px 0px;
          }

          p {
            color: var(--white);
            font-family: "Open Sans";
            font-size: 18px;
            font-style: normal;
            text-align: left;
            font-weight: 600;
            line-height: 144%;
          }
        }
      }
    }
  }
}
.pin-spacer {
  height: 100vh !important;
  padding: 0px !important;
}
.padding {
  padding: 104px 0px 0px 0px !important;
}
@media screen and (max-width: 1024px) {
  .roadmap-sec {
    .service-slider_wrapper {
      height: 400px !important;

      .service-slider {
        .service-slider__item {
          display: flex;
          flex-direction: column;
          gap: 100px;

          .quarter {
            height: 300px !important;

            .enhancing-left-container {
              width: 100%;
              display: none;

              img {
                border-radius: 10px;
                height: 100%;
                margin-top: 50px;
              }
            }

            .enhancing-right-container {
              width: 100%;
            }
          }

          .quarter:nth-child(2) {
            margin-bottom: 50px;
          }

          .quarter:nth-child(3) {
            margin-bottom: 80px;
          }

          .quarter:nth-child(4) {
            margin-bottom: 80px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .roadmap-sec {
    .service-slider_wrapper {
      .service-slider__item {
        gap: 100px;

        .quarter {
          .setFlex {
            flex-direction: column;

            .enhancing-right-container {
              padding: 50px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .roadmap-sec {
    .service-slider_wrapper {
      .service-slider__item {
        gap: 0px !important;

        .quarter {
          margin-bottom: 0px;

          .setFlex {
            .enhancing-right-container {
              padding: 20px;

              h5 {
                font-size: 14px;
                font-weight: 700;
                line-height: normal;
              }

              h3 {
                font-size: 18px;
                line-height: normal;
                padding: 18px 0px;
              }

              p {
                font-size: 12px;
                line-height: normal;
              }
            }
          }
        }

        .quarter:nth-child(2) {
          margin-bottom: 0px;
        }

        .quarter:nth-child(3) {
          margin-bottom: 0px;
        }

        .quarter:nth-child(4) {
          margin-bottom: 0px;
        }
      }
    }
  }
}
