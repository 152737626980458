.headingWrapper {
  width: 60%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 17px;
  //border: 2px solid blue;
  padding-bottom: 100px;
  padding-top: 70px;

  .mainHeading {
    color: #fff;
    text-align: center;
    font-family: "Open Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 58.8px;
  }
  .subHeading {
    color: #a890d3;
    text-align: center;
    font-family: "Open Sans";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 58.8px;
  }
  .para {
    color: var(--primary-light-1, #a890d3);
    text-align: center;
    font-family: "Open Sans";
    font-size: 23px;
    font-style: normal;
    font-weight: 500;
    line-height: 38.8px;
  }
  .demoo {
    display: none;
  }
}

@media screen and (max-width: 1440px) {
  .headingWrapper {
    .mainHeading {
      font-size: 40px;
    }
    .subHeading {
      font-size: 30px;
      line-height: normal;
    }
    .para {
      font-size: 20px;
      line-height: normal;
    }
  }
}

@media screen and (max-width: 1200px) {
  .headingWrapper {
    padding-top: 40px;
    .mainHeading {
      font-size: 35px;
    }
    .subHeading {
      font-size: 28px;
      line-height: normal;
    }
    .para {
      font-size: 16px;
      line-height: normal;
    }
  }
}

@media screen and (max-width: 600px) {
  .headingWrapper {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0px;
    .mainHeading {
      font-size: 30px;
    }
    .subHeading {
      font-size: 22px;
      line-height: normal;
    }
    .para {
      font-size: 15px;
      line-height: normal;
    }
  }
}
