.LiveChatPlatform-main-container {
  background: var(--black-grad, linear-gradient(90deg, #000 0%, #474747 100%));

  .line2 {
    max-width: 1095px;
    height: 1.022px;
    margin: auto;
    opacity: 1;
    background: var(--primary-light, #7d5ba6);
  }
}
