.Twilio-main-container {
  width: 100%;
  max-width: 1134px;
  padding: 24px;
  border-radius: 12px;
  box-shadow: var(--shadow-1);


  .twillo-input-box {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
 


    svg {
      position: absolute;
      right: 20px;
      cursor: pointer;
    }
  }
  h5 {
    color: var(--black);
    text-overflow: ellipsis;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  label {
    color: var(--black);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  p {
    color: #949498;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 20px 0px;
  }

  input {
    margin-top: 10px;
    border-radius: 9.977px;
    padding: 20px;
    background: var(--light-bg);
    color: var(--text1);
    font-weight: 500;
  }

  input::placeholder {
    color: var(--text1);
    font-weight: 500;
  }

  .dropSelect {
    position: relative;
    padding: 20px;
    background-color: var(--light-bg);
    border: none;
    color: var(--text1);
    font-weight: 500;
  }
  .ant-space {
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
  }
  .dropdown-icon {
    transition: transform 0.3s ease;

    &.open {
      transform: rotate(180deg);
    }
  }
}
:where(.css-dev-only-do-not-override-1ae8k9u).ant-dropdown {
  background: white !important;
}
@media only screen and (max-width: 800px) {
}
