.pricing-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  padding-top: 220px;
  padding-bottom: 100px;

  //overflow: auto;

  .subscribe-main-container {
    width: 80%;
    margin: auto;
    gap: 50px;

    h1 {
      color: #fff;
      text-align: center;
      font-family: "Open Sans";
      font-size: 60px;
      font-style: normal;
      font-weight: 700;
      line-height: 133.2%;
      text-transform: capitalize;
    }

    .PricingCard-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 50px;
      margin: auto;
    }
  }
}
@media screen and (max-width: 1500px) {
  .pricing-main-container {
    .subscribe-main-container {
      width: 100%;

      gap: 30px;
      padding: 20px;
      h1 {
        font-size: 50px;
      }
      .PricingCard-container {
        gap: 25px;
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .PricingCard-container {
    gap: 20px !important;
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media only screen and (max-width: 700px) {
  .pricing-main-container {
    padding: 40px 0px;
    height: fit-content;

    .subscribe-main-container {
      width: 100%;
      gap: 40px;

      h1 {
        font-size: 32px;
      }

      .PricingCard-container {
        grid-template-columns: repeat(1, 1fr) !important;
      }
    }
  }
}
