.aIAssist-inner-main-container {
  background-image: url("../../../../public/assets/images/Ellipse\ 65.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  .aIAssist-inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
    gap: 40px;
   // border: 2px solid blue;

    .aIAssist-left-box {
      width: 50%;
     // height: 300px;
    // margin-top: 100px;
     // border: 2px solid blue;

      h3 {
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
      }

      p {
        color: #fff;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        padding: 20px 0px;
      }

      ul {
        li {
          display: flex;
          align-items: start;
          margin-bottom: 15px;
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;

          img {
            margin-right: 5px;
            margin-top: 4px;
          }
        }
      }
    }

    .aIAssist-right-box {
      width: 50%;

      img {
        width: 594px;
        height: 384.111px;
      }
    }
  }

  .aIAssist-inner-container-reverse {
    flex-direction: row-reverse !important;
  }
}

@media only screen and (max-width: 1024px) {
  .aIAssist-inner-main-container {
    .aIAssist-inner-container {
      flex-direction: column;

      .aIAssist-left-box {
        width: 100%;
      }

      .aIAssist-right-box {
        display: flex;
        justify-content: end;
        align-items: end;
        width: 100%;
      }
    }

    .aIAssist-inner-container-reverse {
      flex-direction: column !important;

      .aIAssist-left-box {
        width: 100%;
      }

      .aIAssist-right-box {
        display: flex;
        justify-content: end;
        align-items: end;
        width: 100%;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .aIAssist-inner-main-container {
    margin-bottom: 50px;
    .aIAssist-inner-container {
      margin-top: 30px;
      gap: 10px;
    }
  }
}
