.DashNavbar {
  padding: 22px 63px 14px 100px;
  font-family: "Open Sans";
  position: relative;

  h4 {
    color: var(--black);
    font-size: 26.891px;
    font-weight: 600;
  }

  .right-container {
    gap: 24px;

    .burger-Icon {
      display: none;
      cursor: pointer;
      width: 40px;
      height: 40px;
      z-index: 999;
      margin-left: 40px;
    }

    .search-div {
      border-radius: 500px;
      max-width: 583px !important;
      border: var(--border-primary);
      padding: 5.756px 16.134px;
      gap: 10px;

      input {
        color: var(--text1);
        font-size: 17.479px;
        font-weight: 400;
        letter-spacing: -0.35px;
        background: transparent;
        height: 30px;
        border: none !important;
        padding: 0;
        width: 503px !important;

        &:focus {
          outline: none !important;
          border: none !important;
        }
      }
    }

    .profile-div {
      border-image-slice: 1;
      padding: 0px 20px;
      position: relative;

      img {
        width: 45px;
        height: 45px;
        cursor: pointer;
      }

      span {
        justify-content: left;
        align-items: flex-start;
        line-height: normal;
        font-weight: 400;
        cursor: pointer;

        h6 {
          color: var(--black);
          font-size: 21.513px;
        }

        p {
          color: var(--text1);
          font-size: 18.824px;
          margin-top: 5px;
        }
      }

      &::before {
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        background: linear-gradient(
          180deg,
          rgba(75, 46, 131, 0) -13.24%,
          #a890d3 43.24%,
          rgba(75, 46, 131, 0) 108.47%
        );

        left: 0%;
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    width: 75vw;
    height: 1px;
    background: linear-gradient(
      90deg,
      rgba(75, 46, 131, 0) -13.24%,
      #a890d3 43.24%,
      rgba(75, 46, 131, 0) 108.47%
    );
    left: 50%;
    bottom: 0;
    transform: translate(-50%);
    opacity: 0.7;
  }
}

.linkPill {
}

@media only screen and (max-width: 1520px) {
  .DashNavbar {
    &::before {
      width: 65vw;
    }
  }
}

@media only screen and (max-width: 1310px) {
  .DashNavbar {
    padding: 22px 63px 14px 50px;
    h4 {
      font-size: 22px;
    }
    .right-container {
      width: 600px;
      justify-content: space-between;
      gap: 10px;
      .search-div {
        gap: 10px;
        width: 100% !important;

        input {
          width: 100% !important;
        }
      }
      .profile-div {
        img {
          width: 45px;
          height: 45px;
        }

        span {
          h6 {
            font-size: 18px;
          }

          p {
            font-size: 16px;
            white-space: nowrap;
          }
        }
      }
      .burger-Icon {
        display: block;
      }
    }
    &::before {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 650px) {
  .DashNavbar {
    padding: 22px 20px 14px 20px;

    .right-container {
      justify-content: end;
      .search-div {
        display: none;
      }
      .profile-div {
        display: none;
      }
    }
  }
}
