.dryCleaning-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 100vh;
  padding: 170px 0px 170px 0px;
  // padding: 300px 0px 250px 0px;
  background: var(--black-grad, linear-gradient(90deg, #000 0%, #474747 100%));
  //border: 2px solid rgb(199, 31, 31);

  .dryCleaning-centered-container {
    width: 75%;
    margin: auto;
    display: flex;
    gap: 50px;

    .dryCleaning-left-container {
      position: relative;
      display: flex;
      justify-content: start;
      align-items: center;
      width: 40%;
      z-index: 999;

      &::after {
        position: absolute;
        content: "";
        right: -40%;
        bottom: 0;
        width: 641px;
        height: 606px;
        transform: rotate(90deg);
        border-radius: 641px;
        opacity: 0.3;
        background: linear-gradient(235deg, #a890d3 15.54%, #4b2e83 84.87%);
        filter: blur(118.12164306640625px);
        z-index: -2;
      }
    }

    .dryCleaning-right-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 60%;

      h2 {
        color: #fff;
        font-family: "Open Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        text-align: left;
      }

      h3 {
        color: var(--primary-light-1, #a890d3);
        font-family: "Open Sans";
        font-size: 32px;
        font-style: normal;
        padding: 15px 0px;
        font-weight: 700;
      }

      p {
        color: #fff;
        font-family: "Open Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
        text-align: left;
        max-width: 785px;
      }

      p:nth-child(2) {
        padding-bottom: 10px;
      }
    }
  }
}

.setpadding {
  padding: 300px 0px 300px 0px;
}
.setfont {
  font-size: 48px !important;
}
@media only screen and (max-width: 1024px) {
  .dryCleaning-main-container {
    // height: 100vh;
    padding: 170px 0px 80px 0px;

    .dryCleaning-centered-container {
      width: 80%;
      justify-content: center;
      align-items: center;
      flex-direction: column-reverse;

      .dryCleaning-left-container {
        width: 100%;
      }

      .dryCleaning-right-container {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .dryCleaning-main-container {
    height: fit-content;
    padding: 40px 10px 0px 10px;
    gap: 10px;

    .dryCleaning-centered-container {
      width: 95%;
      gap: 10px;

      .dryCleaning-right-container {
        justify-content: center;
        align-items: center;

        h2 {
          font-size: 32px;
          text-align: center;
        }
        h3 {
          font-size: 20px;
          text-align: center;
        }
        p {
          text-align: center;
          font-size: 14px;
          line-height: 25px;
        }
      }

      .dryCleaning-left-container {
        width: 100%;
        padding-bottom: 50px;

        img {
          margin-top: 30px;
        }
      }
    }
  }
}

@media only screen and (max-height: 700px) {
  .dryCleaning-main-container {
    padding: 180px 0px 220px 0px;
  }
}

@media screen and (max-height: 700px) and (max-width: 600px) {
  .dryCleaning-main-container {
    padding: 70px 0px 70px 0px;
  }
}
