.table-div {
  border-radius: 8px;

  table {
    thead {
      background-color: transparent !important;
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 500 !important;
      line-height: normal;

      tr {
        background-color: #f8f8f8;
        color: var(--black);
        border-bottom: 1px solid #e5e5e5;

        th {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
    }

    tbody {
      background-color: transparent !important;

      tr {
        background-color: transparent !important;
        border: 1px solid rgba(255, 255, 255, 0.1);

        th {
          color: var(--text1);
          border-bottom: 1px solid #e5e5e5;
          font-family: "Open Sans";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }

        td {
          color: var(--text1);
          border-bottom: 1px solid #e5e5e5;
          font-family: "Open Sans";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          span {
            .eye {
              width: 20px;
              height: 20px;
            }
          }
        }

        .table-pill {
          width: fit-content !important;
          height: fit-content !important;
          color: var(--text1);
          font-family: "Open Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          padding: 5px 12px !important;
          border-radius: 120px;
          text-align: center;
          background: #e5e5e5;
          margin: 10px 0px !important;
        }
      }
    }
  }


}



.custom-popconfirm {
  .ant-btn-primary {
    background-color: black;
    border-color: black;
    color: white;
  }

  .ant-btn-primary:hover {
    background-color: white;
    border-color: black;
    color: black;
  }
}