.linkPill {
    display: flex;
    justify-content: center;
    align-content: center;
    border-radius: 500px;
    background: var(--primary-color);
    padding: 8px 25px;
    gap: 10px;
    width: fit-content;
    height: fit-content;
    color: var(--white);
    font-size: 14px;
    font-weight: 300;
    font-family: "Open Sans";
    cursor: pointer;
    transition: var(--tranition-half);

    &:hover {
        background-color: var(--primary-hover-color);
    }
    &.selected {

    }
    span {
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // width: 30px;
        // height: 30px;
        // background-color: var(--secondary-color);
        // border-radius: 500px;
    }

    p {
        margin-top: 3px;
    }
    .linkPill-arrow-select {
        display: flex;
        align-items: center;
        margin-left: auto;
        position: relative;

        .linkPill-options {
            position: absolute;
            top: 100%;
            right: 0;
            background: var(--primary-color);
            border: 1px solid #ddd;
            border-radius: 4px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            z-index: 1000;
            width: fit-content;
            height: fit-content;
            div {
                padding: 8px 16px;
                cursor: pointer;
                white-space: nowrap; // Ensures text is on one line
                overflow: hidden;    // Ensures overflow is hidden
                text-overflow: ellipsis; // Adds ellipsis for overflow text
                font-size: 15px;
                font-weight: 300;
                font-family: "Open Sans";

                &:hover {
                    color: black;
                    background: #f2f2f2;
                }
            }
        }
}
}

.primary-box-shadow {
    // box-shadow: 0px 0px 16px 0px #A890D3;
}

.demoBtn {
    border-radius: 30px;
    background: var(--primary-light, #7d5ba6);
    color: #fff;
    padding: 10px 28px;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    text-transform: capitalize;
    position: relative;
    z-index: 999;
    // padding-left: 30px;

    // &::before {
    //     content: url("../../src/images/playaBtn.svg");
    //     position: absolute;
    //     left: 40px;
    //     top: 6px;
    // }
}

// .purplePara {
//   color: var(--primary-dark, #4b2e83);
//   font-family: "Open Sans";
//   font-size: 20px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 26px;
// }