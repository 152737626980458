.navbar-container {
  position: absolute;
  padding-top: 10px;
  padding-bottom: 10px;
  top: 0px;
  left: 0;
  right: 0;
  z-index: 1000 !important;
  //border: 2px solid green;
  background-color: black;
}

.navbar-inner-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: auto;
  border-radius: 158px;
  color: white;
  // background: #fff;
  padding: 6px 20px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);

  .logo-container {
    img {
      width: 118.876px;
      height: 76.77px;
    }
  }

  .menu-container {
    ul {
      display: flex;
      // gap: 15px;
      li {
        position: relative;
        color: white;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        text-transform: capitalize;
        margin: 0px 30px;
        transition: all 0.3s ease;
        &:hover {
          color: var(--primary-light-1, #a890d3);
        }
        // height: 30px;

        a {
          .icon-rotate {
            transform: rotate(0deg);
            transition: all 0.3s ease-in-out;
          }
          // &:after {
          //   content: "";
          //   display: block;
          //   height: 3px;
          //   left: 50%;
          //   transform: translate(-50%);
          //   bottom: -18%;
          //   position: absolute;
          //   border-radius: 5px;
          //   background: var(
          //     --3-color-grad,
          //     linear-gradient(90deg, #4b2e83 0%, #a890d3 52%, #4b2e83 100%)
          //   );
          //   opacity: 0.6;
          //   transition: width 0.8s ease 0s, opacity 0.3s ease 0s;
          //   width: 30%;
          // }
          &:hover {
            .icon-rotate {
              color: #a890d3 !important;
              transform: rotate(180deg);
              transition: all 0.3s ease-in-out;
            }
          }
          &:hover:after {
            width: 100%;
            opacity: 0.9;
          }

          &.active {
            color: #a890d3 !important;
            // &:after {
            //   width: 100%;
            //   content: "";
            //   display: block;
            //   height: 3px;
            //   left: 50%;
            //   transform: translate(-50%);
            //   bottom: -18%;
            //   position: absolute;
            //   border-radius: 5px;
            //   background: var(
            //     --3-color-grad,
            //     linear-gradient(90deg, #4b2e83 0%, #a890d3 52%, #4b2e83 100%)
            //   );
            //   opacity: 0.8;
            //   transition: width 0.8s ease 0s, opacity 0.3s ease 0s;
            // }
          }
        }
      }

      .liItem {
        color: #000;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
      }

      .anticon svg {
        color: #4b2e83;
      }
    }
  }

  .navbarbtn-container {
    button {
      // width: 175px;
      display: inline-flex;
      height: 60px;
      padding: 24px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 72px;
      background: var(--primary-dark, #4b2e83);
      color: #fff;
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      //  text-transform: uppercase;
    }
  }
}

.solutions-hover-container {
  position: absolute;
  margin-left: -50px;
  // margin-top: 110px;
  color: #fff;
  display: flex !important;
  justify-content: space-between;
  padding-top: 57px;
  gap: 15px;
  // border-radius: 9px;
  // background: rgba(25, 1, 69, 0.1);
  // backdrop-filter: blur(46.43333435058594px);
  overflow: hidden;
  z-index: 9999;

  .solutionsmenu-left-box {
    width: 300px;
    // width: 60%;
    padding: 15px 16px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 10px;
    background: var(--primary-light-1, #a890d3);

    h5 {
      color: #fff;
      font-family: "Open Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 160%;
      text-transform: capitalize;
      margin-bottom: 10px;
    }

    .solutions-meet-box {
      .solutions-meetImg-box {
        width: 40%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .solutions-meetTxt-box {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        p {
          color: #fff;
          font-family: "Open Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%;
          text-transform: capitalize;
        }

        h6 {
          color: var(--primary-dark, #4b2e83);
          font-family: "Open Sans";
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 160%;
          text-transform: capitalize;
        }
      }
    }

    .solutions-contact-box {
      padding-left: 15px;

      h6 {
        margin-top: 20px;
        color: #fff;
        font-family: "Open Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
        text-transform: capitalize;
      }

      ul {
        padding-top: 10px;
        display: flex;
        align-items: start;
        justify-content: start;
        flex-direction: column;
        width: 100%;

        li {
          display: flex;
          color: #fff;
          font-family: "Open Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%;
          text-transform: capitalize;
          gap: 10px;
          margin-bottom: 15px;
          margin-left: 0;

          img {
            width: 18px;
            height: 18px;
            object-fit: cover;
            margin-top: 5px;
          }
        }
      }
    }
  }

  .solutionsmenu-right-box {
    // width: 40%;
    width: 248px;
    height: fit-content;
    padding: 13px 35px;
    border-radius: 10px 0px;
    background: #fff;

    h5 {
      color: var(--primary-light-1, #a890d3);
      font-family: "Open Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 160%; /* 28.8px */
      text-align: center;
    }

    .Industries-options-conatainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 11px;
      margin-top: 20px;

      .gradient-line {
        background: linear-gradient(
          90deg,
          rgba(87, 74, 109, 0) 0%,
          #a890d3 50%,
          rgba(87, 74, 109, 0) 100%
        );
        width: 100%;
        height: 1px;
      }

      .Industries-options-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px 10px 10px;
        width: 100%;
        align-items: center;
        border-radius: 12px;
        background: #fff;
        color: var(--primary-dark, #4b2e83);
        font-family: "Open Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
        cursor: pointer;

        .sol-right-icon {
          transform: translateX(0%);
          transition: transform 0.3s ease-in-out;
        }

        &:hover {
          .sol-right-icon {
            transform: translateX(50%);
          }
        }
      }
    }
  }

  .industries-optContent-container {
    width: 300px;
    height: fit-content;
    // width: 0px !important;
    // height: 0px !important;
    padding: 15px;
    border-radius: 10px;
    background: #fff;

    h5 {
      color: var(--black, #000);
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 160%;
      text-transform: capitalize;
    }

    .inductries-line {
      width: 77px;
      height: 1px;
      margin-top: 5px;
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 0%,
        #000 49.26%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    p {
      color: var(--black, #000);
      font-family: "Open Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      text-transform: capitalize;
      padding-top: 15px;
    }
  }

  // .IndustriesTabs {
  //   opacity: 0;
  // transition: opacity 2s ease;
  // }
  // .active1 {
  //   opacity: 1;
  // }
}
.platform-main-outer {
  padding-top: 57px;
  position: absolute;
  left: 30%;
  .platform-hover-container {
    color: #fff;
    display: flex !important;
    justify-content: space-between;
    padding: 23px 31px;
    gap: 11px;
    margin-left: -100px;
    border-radius: 10px 0px;
    background: #fff;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    z-index: 99999;
    top: 40px;
    .platform-items {
      cursor: pointer;
      width: 219px;
      height: 45px;
      padding: 10px 15px 10px 10px;
      align-items: center;
      gap: 13px;
      border-radius: 12px;
      background: #fff;

      .left {
        gap: 13px;

        h6 {
          color: var(--primary-dark, #4b2e83);
          font-family: "Open Sans";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 160%;
          /* 19.2px */
        }
      }

      .right-cursor {
        transform: translateX(0%);
        transition: transform 0.3s ease-in-out;
      }

      &:hover {
        .right-cursor {
          transform: translateX(50%);
        }
      }
    }
  }
}

.mobile-navbar {
  display: none;
}

.activeClass {
  color: #a890d3 !important;
  .icon-rotate {
    color: #a890d3 !important;
    transform: rotate(180deg);
    transition: all 0.3s ease-in-out;
  }
  // &:after {
  //   width: 100%;
  //   content: "";
  //   display: block;
  //   height: 3px;
  //   left: 50%;
  //   transform: translate(-50%);
  //   bottom: -18%;
  //   position: absolute;
  //   border-radius: 5px;
  //   background: var(
  //     --3-color-grad,
  //     linear-gradient(90deg, #4b2e83 0%, #a890d3 52%, #4b2e83 100%)
  //   );
  //   opacity: 0.8;
  //   transition: width 0.8s ease 0s, opacity 0.3s ease 0s;
  // }
}

@media only screen and (max-width: 1700px) {
  .platform-hover-container {
    left: 33%;
  }
}

@media only screen and (max-width: 1620px) {
  .navbar-container {
    .navbar-inner-section {
      width: 90%;

      .logo-container {
        img {
          max-width: 90%;
        }
      }

      .menu-container {
        ul {
          li {
            font-size: 16px;
            margin: 10px 20px;
            height: 30px;
          }
        }
      }

      .navbarbtn-container {
        button {
          width: 153px;
          padding: 13px;
          gap: 10px;
          font-size: 16px;
        }
      }
    }
  }

  .ant-space-item,
  .ant-space {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 1440px) {
  .platform-hover-container {
    left: 30%;
  }

  .navbar-container {
    .navbar-inner-section {
      width: 100%;

      .logo-container {
        img {
          width: 90px;
        }
      }
    }
  }
}

@media screen and (max-width: 1360px) {
  .solutions-hover-container {
    left: 20%;
  }
}

@media only screen and (max-width: 1280px) {
  .platform-hover-container {
    left: 27%;
  }

  .solutions-hover-container {
    left: 5%;
  }

  .navbar-container .navbar-inner-section .navbarbtn-container button {
    // width: 110px;
    padding: 13px;
    gap: 10px;
    font-size: 14px;
  }
}

@media screen and (max-width: 1200px) {
  .navbar-container .navbar-inner-section .menu-container ul li {
    font-size: 14px;
    margin: 10px;
    height: 30px;
  }

  .navbar-container {
    .navbar-inner-section {
      justify-content: space-around;
    }
  }
}

@media screen and (max-width: 1000px) {
  .solutionsmenu-left-box {
    display: none;
  }
}

@media screen and (max-width: 950px) {
  .solutions-hover-container {
    left: 20%;
  }
}

@media screen and (max-width: 800px) {
  .solutions-hover-container {
    left: 10%;
  }
}

@media screen and (max-width: 600px) {
  .navbar-container,
  .solutions-hover-container,
  .platform-hover-container {
    display: none;
  }

  .mobile-navbar {
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;

    .mobileLogo {
      width: auto;
      height: 40px;
    }

    .menu-btn {
      background: transparent;
      border: none;
      width: 56px;
      height: 39px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      .menu-btn-icon {
        width: 28px;
        height: 28px;
      }
    }
  }

  .logostyle {
    width: auto;
    height: 40px;
  }

  ul {
    li {
      color: #000;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      text-transform: capitalize;
      list-style: none;

      a {
        color: black;
        text-decoration: none;

        &.active {
          font-weight: 700;
          color: #000000;
        }
      }
    }
  }

  .signupBtn {
    width: 120px;
    height: fit-content;
    padding: 12px;
    border-radius: 72px;
    background: var(--primary-dark, #4b2e83);
    color: #fff;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
  }

  .activeClass {
    font-weight: 700;
    color: #000000;
  }

  .mobil-dropdown {
    border: 1px solid black;
    border-radius: 10px;
    width: fit-content;
    margin-top: 10px;
    width: 170px;
    padding: 2px 0px 2px 2px !important;

    li {
      margin: 10px 5px !important;

      img {
        width: 20px;
        height: 20px;
      }

      a {
        color: #4b2e83;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        text-transform: capitalize;

        &.active {
          font-weight: 500;
          color: #4b2e83;
        }
      }
    }
  }

  :where(.css-dev-only-do-not-override-1ae8k9u) a:hover {
    color: #4b2e83;
  }

  // .ant-drawer-content-wrapper {
  //   width: 388px;
  // }
}
