.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  padding: 10px;

  &.isBackdropClicked {
    display: none;
  }
}

.modal {
  max-width: 900px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  padding: 25px;
  border: 1.247px solid var(--light-bg);
  border-radius: 14.965px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--light-bg) var(--dark-bg);

  .modal-header {
    display: flex;
    justify-content: center;
    align-items: start;
    color: var(--text1);

    .modal-header-opt-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 500px;
        border: 2px solid var(--light-bg);
      }

      p {
        color: var(--text1);
        text-align: center;
        font-family: "Open Sans";
        font-size: 16px;
        font-weight: 500;
        margin-top: 12px;
      }
    }

    .modal-header-opt-box-line {
      width: 100px;
      height: 1px;
      margin-top: 20px;
      background-color: var(--light-bg);
    }

    .modal-header-opt-box-line:nth-child(2) {
      margin-left: 20px;
    }
  }

  .modal-content {
    width: 100%;
    color: var(--black);
    text-align: center;
    padding: 20px;
    border-radius: 9.977px;
    text-align: left;
    // background: #e5e5e5;
    background: #f8f8f8;

    .theme-btn-light {
      font-weight: 500;
      font-size: 22.448px;
      padding: 14px 50px;
      margin-top: 20px;
    }
  }

  /* Custom scrollbar styles for WebKit browsers */
  .modal::-webkit-scrollbar {
    width: 10px;
  }

  .modal::-webkit-scrollbar-track {
    background: var(--dark-bg);
  }

  .modal::-webkit-scrollbar-thumb {
    background-color: var(--light-bg);
    border-radius: 6px;
    border: 2px solid var(--dark-bg);
  }
}
.modal-active {
  span {
    border: 2px solid #7d5ba6 !important;
    color: #7d5ba6 !important;
  }
  p {
    color: #7d5ba6 !important;
  }
}
@media only screen and (max-width: 600px) {
  .modal {
    justify-content: start;
    height: 100%;
    max-height: unset !important;
    padding: 20px;
    .modal-content {
      padding: 10px;
      .theme-btn-light {
        width: 100%;
      }
    }
  }
}
