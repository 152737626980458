.KnowledgeLibraryTab-container {
  padding: 20px;
  border-radius: 16px;
  border: 1px solid #d9d9d9;
  background: #f8f8f8;

  .KnowledgeLibraryTab-search-box {
    display: flex;
    width: 52%;
    max-width: 450px;
    align-items: center;
    gap: 8px;
    border-radius: 500px;
    border: 1px solid #dcdcdc;
    background: var(--white);

    .search-icon {
      font-size: 22px;
      margin-right: 18px;
    }

    input {
      padding: 10px 20px;
      border-radius: 500px;
      width: 100%;
      border: none;
      color: #000;
      &::placeholder {
        color: #6e6e70;
      }
    }
  }

  .theme-btn-tran {
    padding: 10px 24px;
  }
}

.modal-backdrop.isBackdropClicked {
  display: none;
}

@media only screen and (max-width: 750px) {
  .KnowledgeLibraryTab-container {
    padding: 10px;
    .flexCol {
      flex-direction: column;
    }

    .KnowledgeLibraryTab-search-box {
      width: 100%;
    }
    .btns {
      flex-direction: column;
      width: 100%;
      button {
        width: 100%;
      }
    }
  }
}
