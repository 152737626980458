.ChatWithAgent-main-container {
  padding: 22px 63px 14px 100px;
  color: #000;

  h5 {
    color: var(--black);
    text-overflow: ellipsis;
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  label {
    color: var(--black);
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
  }

  .ChatWithAgent-input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 450px;

    img {
    }

    .ChatWithAgent-input-box {
      border-radius: 500px;
      border: 1px solid #dcdcdc;
      background: var(--white);
      display: flex;
      align-items: center;
      width: 100%;

      input {
        padding: 10px 16px;
        border-radius: 500px;
        border: none;
        width: 100%;
      }

      .search-icon {
        margin-right: 20px;
        cursor: pointer;
      }
    }
  }

  .ChatWithAgent-stats-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 950px;
    padding: 18px 40px;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: var(--shadow-1);

    .ChatWithAgent-stats-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      // &::after {
      //     position: absolute;
      //     content: '';
      //     width: 75px;
      //     height: 75px;
      //     box-shadow: 0px 0px 16px 0px #A890D3;
      // }

      h5 {
        color: #696969;
        font-family: "Open Sans";
        font-size: 18px;
        font-weight: 400;
        padding-bottom: 10px;
      }

      h6 {
        color: #2b2b2b;
        font-family: "Open Sans";
        font-size: 26px;
        font-weight: 400;
      }
    }
  }

  .ChatWithAgent-mainChat-container {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;

    .ChatWithAgent-InnerChat-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 310px;
      height: 560px;
      border-radius: 16.109px;
      background: var(--white);
      box-shadow: 0px -4.027px 16.109px 0px rgba(0, 0, 0, 0.15);

      .ChatWithAgent-InnerChat-head {
        padding: 20px 22px 20px 22px;
        background: var(--light-1, #f6f6f6);
      }

      .ChatWithAgent-agent-box {
        display: flex;
        align-items: center;
        padding: 14px 20px;
        gap: 10px;
        border-bottom: 1px solid var(--light-bg);
        cursor: pointer;
       

        img {
        }

        span {
          h5 {
            color: var(--primary-color);
            font-family: "Open Sans";
            font-size: 16px;
            font-weight: 500;
          }

          p {
            color: var(--text1);
            font-family: "Open Sans";
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
    }

    .ChatWithAgent-InnerChat-container-chat {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 430px;
      height: 560px;
      border-radius: 16.109px;
      background: var(--white);
      box-shadow: 0px -4.027px 16.109px 0px rgba(0, 0, 0, 0.15);

      .ChatWithAgent-InnerChat-head {
        padding: 20px 22px 20px 22px;
        background: var(--light-1, #f6f6f6);

        img {
        }

        h5 {
          color: var(--primary-color);
          font-family: Arial;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
        }
      }

      .chat-middle {
        padding: 20px;

        .received-txt-div {
          img {
            width: 22.986px;
            height: 21.709px;
          }

          .received-txt {
            color: #fff;
            font-size: 13.775px;
          }
        }

        .sender-txt-div {
          justify-content: right;

          .sender-txt {
            font-size: 13.775px;
          }
        }

        .timer-item {
          p {
            color: #fff;
            font-family: "Open Sans";
            font-size: 7.627px;
            font-style: normal;
            font-weight: 400;
            line-height: 158.5%;
            /* 12.089px */
            border-radius: 14.339px;
            background: var(--primary-dark, #4b2e83);
            padding: 0.305px 4.881px;
            width: fit-content;
            margin: 10px auto;
          }
        }
      }

      .chat-bottom {
        width: 100%;
        position: absolute;
        bottom: 0;
        padding: 13px 15.506px 13px 20px;
        background: #f0f0f0;
        border-bottom-left-radius: 8.906px;
        border-bottom-right-radius: 8.906px;

        input {
          color: black;
          font-family: "Open Sans";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 158.5%;
          /* 19.02px */
          background: transparent;
          width: 100%;
          height: 19px;
          outline: none !important;
          border: none !important;
        }

        input:focus {
          outline: 1px solid #f0f0f0 !important;
          border: 1px solid #f0f0f0 !important;
        }
      }
    }

    .ChatWithAgent-InnerChat-container-center {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 330px;
      // height: 560px;
      border-radius: 16.109px;
      background: var(--white);
      box-shadow: 0px -4.027px 16.109px 0px rgba(0, 0, 0, 0.15);

      .ChatWithAgent-InnerChat-head {
        padding: 20px 22px 20px 22px;
        background: var(--light-1, #f6f6f6);

        h5 {
          color: var(--primary-color);
          font-family: Arial;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
        }
      }

      .ChatWithAgent-box-center {
        padding: 20px 24px 5px 24px;
        border-bottom: 1px solid #e5e5e5;

        h5 {
          color: var(--text1);
          font-family: "Open Sans";
          font-size: 16px;
          font-weight: 500;
          padding-bottom: 5px;
        }

        span {
          color: var(--text1);
          font-family: "Open Sans";
          font-size: 13px;
          font-weight: 500;
          display: flex;
          gap: 10px;
          padding-right: 20px;
        }

        ul {
          li {
            justify-content: space-between;

            margin-bottom: 5px;
            color: #343434;
            font-family: "Open Sans";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;

            /* 158.333% */
            span {
              // width: 160px;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .custom-checkbox {
                margin-top: -28px;
              }
            }

            .set-wid {
              width: 150px;
            }
          }
        }
      }

      .ChatWithAgent-box-center:nth-child(3) {
        padding: 10px 24px 5px 24px;
      }

      .ChatWithAgent-box-center:nth-child(4) {
        padding: 10px 24px 5px 24px;
      }

      .ChatWithAgent-box-center:nth-child(5) {
        padding: 10px 24px 5px 24px;
        border-bottom: none;
      }
    }

    .ChatWithAgent-InnerChat-container-history {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 330px;
      // height: 560px;
      border-radius: 16.109px;
      background: var(--white);
      box-shadow: 0px -4.027px 16.109px 0px rgba(0, 0, 0, 0.15);

      .ChatWithAgent-InnerChat-head {
        padding: 20px 22px 20px 22px;
        background: var(--light-1, #f6f6f6);

        h5 {
          color: var(--primary-color);
          font-family: Arial;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
        }
      }

      .ChatWithAgent-box-center {
        padding: 20px 24px 5px 24px;
        border-bottom: 1px solid #e5e5e5;

        h5 {
          color: var(--text1);
          font-family: "Open Sans";
          font-size: 16px;
          font-weight: 500;
          padding-bottom: 5px;
        }

        p {
          color: #343434;
          font-family: "Open Sans";
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
        }

        ul {
          li {
            span {
              color: #343434;
              font-family: "Open Sans";
              font-size: 12px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1550px) {
  .ChatWithAgent-mainChat-container {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 30px !important;

    .ChatWithAgent-InnerChat-container {
      max-width: 100% !important;
    }

    .ChatWithAgent-InnerChat-container-center {
      max-width: 100% !important;
    }

    .ChatWithAgent-InnerChat-container-history {
      max-width: 100% !important;
    }
  }

  .ChatWithAgent-InnerChat-container-chat {
    width: 100% !important;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 1310px) {
  .ChatWithAgent-main-container {
    padding: 22px 20px 14px 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .ChatWithAgent-main-container {
    padding: 0px 20px 14px 20px;

    .flexcol {
      flex-direction: column;
      justify-content: start;
      align-items: start;

      .ChatWithAgent-input-container {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .ChatWithAgent-box-center {
    ul {
      li {
        span {
          width: 100% !important;
        }
      }
    }
  }

  .ChatWithAgent-stats-container {
    padding: 18px 20px !important;
    display: flex !important;
    flex-wrap: wrap;
    gap: 10px;
  }

  .ChatWithAgent-mainChat-container {
    grid-template-columns: repeat(1, 1fr) !important;

    .ChatWithAgent-InnerChat-container {
      width: 100% !important;
      max-width: 100% !important;
    }

    .ChatWithAgent-InnerChat-container-center {
      width: 100% !important;
      max-width: 100% !important;
    }

    .ChatWithAgent-InnerChat-container-history {
      width: 100% !important;
      max-width: 100% !important;
    }

    .ChatWithAgent-InnerChat-container-chat {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}
