.workWrapper {
  // padding: 20px;
  //border: 2px solid red;
  background: var(--black-grad, linear-gradient(90deg, #000 0%, #474747 100%));
  padding-bottom: 50px;

  .howItWorkContainer {
    // width: 56%;
    max-width: 1070px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 40px;
    padding-bottom: 50px;
    //border: 2px solid green;
  }
  .purpleDiv {
    width: 1060px;
    height: 1px;
    background: var(--primary-light, #7d5ba6);
    margin: 0 auto;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 1200px) {
  .workWrapper {
    .purpleDiv {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .workWrapper {
    padding-top: 20px;
  }
}

@media screen and (max-width: 600px) {
  .workWrapper .howItWorkContainer {
    margin-top: 40px;
  }
}
