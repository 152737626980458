.Smrt-main-conatiner {
  width: 100%;
  padding: 24px 28px;
  border-radius: 12px;
  box-shadow: var(--shadow-1);

  .Smrt-maininner-conatiner {
    display: flex;
    // padding: 20px;
    flex-direction: column;
    border-radius: 8px;

    
  .SMRT-input-box {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;


  
    svg {
      position: absolute;
      right: 20px;
      cursor: pointer;
    }
  }


    .SMRT-input-box {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
  
  
    
      svg {
        position: absolute;
        right: 20px;
        cursor: pointer;
      }
    }
  

    h5 {
      color: var(--black);
      font-family: "Open Sans";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    p {
      color: var(--text1);
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 10px 0px 20px 0px;
    }
  }
}
