.table-div {
  border-radius: 8px;

  table {
    thead {
      background-color: transparent !important;
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 500 !important;
      line-height: normal;

      tr {
        background-color: #f8f8f8;
        color: var(--black);

        th {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
    }

    tbody {
      background-color: transparent !important;

      tr {
        background-color: transparent !important;
        border: 1px solid rgba(255, 255, 255, 0.1);

        th {
          color: var(--text1);
          border-bottom: 1px solid #f8f8f8;
          font-family: "Open Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }

        td {
          color: var(--text1);
          border-bottom: 1px solid #f8f8f8;
          font-family: "Open Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }

        .table-pill {
          width: fit-content !important;
          height: fit-content !important;
          color: var(--text1);
          font-family: "Open Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          padding: 5px 12px !important;
          border-radius: 120px;
          text-align: center;
          background: #e5e5e5;
          margin: 10px 0px !important;
        }
      }
    }
  }
}

// Pagination styling
.ant-pagination {
  .ant-pagination-item {
    color: black;
    border-radius: 9.412px;
    border: 1.176px solid #a890d3;
  }
  .ant-pagination-item-active {
    color: black;
    border-radius: 9.412px;
    border: 1.176px solid #a890d3;
    a {
      border-radius: 9.412px;
      border: 1.176px solid #a890d3;
      background: #a890d3;
      color: black;
      &:hover {
        border-radius: 9.412px;
        border: 1.176px solid #a890d3;
        background: #a890d3;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .table-div {
    table {
      tbody {
        tr {
          th {
            font-size: 13px;
            padding-left: 10px;
            padding-right: 10px;
          }
          td {
            span {
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }
}


.fileURL-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
}