.requestDemo-main-container {
  padding: 130px 20px 118px 20px;

  .requestDemo-centered-container {
    width: 78%;
    margin: auto;
    border-radius: 30px;
    background: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    padding: 80px;
    margin-top: 500px;

    h2 {
      color: var(--Main-Logotype, #011334);
      font-family: "Open Sans";
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
    }

    .requestDemo-flex-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      .requestDemo-flex-box-left {
        width: 50%;
        padding-left: 20px;

        h5 {
          width: 90%;
          color: var(--black, #000);
          font-family: "Open Sans";
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 58px;
        }

        h6 {
          width: 80%;
          color: var(--black, #000);

          /* H2 */
          font-family: "Open Sans";
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: 46px;
          /* 127.778% */
          margin-top: 10px;
        }
      }

      .requestDemo-flex-box-right {
        padding: 30px;
        width: 50%;
        border-radius: 10px;
        border: 1px solid var(--Gray-4, #bdbdbd);
        background: var(--white, #fff);
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);

        .requestDemo-twoInputbox {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 20px;

          input {
            width: 100%;
            border-radius: 10px;
            border: 1px solid var(--Gray-3, #828282);
            background: #f9f9f9;
          }
        }

        input {
          width: 100%;
          border-radius: 10px;
          border: 1px solid var(--Gray-3, #828282);
          background: #f9f9f9;
          margin-top: 20px;
        }

        textarea {
          width: 100%;
          border-radius: 10px;
          border: 1px solid var(--Gray-3, #828282);
          background: #f9f9f9;
          margin-top: 20px;
        }

        .send-request-btn {
          width: 100%;
          border-radius: 24px;
          background: var(--primary-light, #7d5ba6);
          padding: 12px 120px;
          margin-top: 20px;
          color: var(--white, #fff);
          font-family: "Open Sans";
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }

    .whatSeeHeading {
      // border: 2px solid green;
      h6 {
        color: var(--black, #000);
        font-family: "Open Sans";
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 46px;
        /* 127.778% */
        margin-top: 20px;
      }

      p {
        color: var(--primary-dark, #4b2e83);
        font-family: "Open Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        /* 130% */
      }
    }

    .responseDiv {
      margin-top: 20px;

      strong {
        color: var(--black, #000);
        font-family: "Open Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
        // list-style-type: disc;
      }

      span {
        color: var(--primary-dark, #4b2e83);
        font-family: "Open Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
      }
    }
  }

  .marginminus {
    margin-top: -80px !important;
  }

  .topminus {
    margin-top: -50px !important;
  }

  .topminusdiv {
    margin-top: -250px !important;
    margin-bottom: -57px !important;
  }

  .shadow {
    box-shadow: 0px 0px 70px #7d5ba6;
  }
}

@media only screen and (max-height: 700px) {
  .requestDemo-centered-container {
    // margin-top: 300px !important;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1) !important;
  }
}

@media screen and (max-width: 1750px) {
  .requestDemo-main-container
    .requestDemo-centered-container
    .requestDemo-flex-container
    .requestDemo-flex-box-left
    h5 {
    width: 100%;
    color: var(--black, #000);
    font-family: "Open Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
  }

  .requestDemo-main-container
    .requestDemo-centered-container
    .requestDemo-flex-container
    .requestDemo-flex-box-left
    h6 {
    width: 100%;

    font-size: 31px;
    font-style: normal;
    font-weight: 600;
    line-height: 46px;
  }
}

@media only screen and (max-width: 1440px) {
  .requestDemo-main-container {
    .requestDemo-centered-container {
      width: 90%;
      padding: 40px;

      h2 {
        font-size: 42px;
      }
    }
  }

  .requestDemo-main-container
    .requestDemo-centered-container
    .requestDemo-flex-container
    .requestDemo-flex-box-left
    h5 {
    font-size: 30px;
    font-weight: 700;
  }

  .requestDemo-main-container
    .requestDemo-centered-container
    .requestDemo-flex-container
    .requestDemo-flex-box-left
    h6 {
    width: 100%;

    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px;
  }

  .requestDemo-main-container
    .requestDemo-centered-container
    .whatSeeHeading
    h6 {
    font-size: 26px;
  }

  .requestDemo-main-container {
    .requestDemo-centered-container {
      .whatSeeHeading {
        p {
          font-family: "Open Sans";
          font-size: 18px;
        }
      }

      .responseDiv {
        span {
          font-size: 18px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .requestDemo-main-container {
    padding: 40px 20px;

    .requestDemo-centered-container {
      width: 100%;
      padding: 20px;
      box-shadow: none;
      margin-top: 20px;

      h2 {
        font-size: 24px;
        text-align: center;
      }

      .requestDemo-flex-container {
        flex-direction: column;

        .requestDemo-flex-box-left {
          width: 100%;
          padding-left: 0px;
          text-align: center;

          h5 {
            width: 100%;
            font-size: 27px;
            margin-top: 10px;
            line-height: 24px;
          }

          p {
            width: 100%;
            margin-top: 10px;
            line-height: 24px;
            font-size: 13px;
          }
        }

        .requestDemo-flex-box-right {
          padding: 0px 0px 20px 0px;
          width: 100%;
          box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0);
          border: none;

          .requestDemo-twoInputbox {
            grid-template-columns: repeat(1, 1fr);
            gap: 5px;
          }

          .send-request-btn {
            padding: 12px 10px;
            font-size: 14px;
          }
        }
      }
    }

    .topminusdiv {
      margin-top: 0px !important;
      margin-bottom: 20px !important;
    }

    .marginminus {
      margin-top: 20px !important;
    }
  }

  .requestDemo-main-container {
    .requestDemo-centered-container {
      .whatSeeHeading {
        p {
          font-size: 16px;
        }
      }

      .responseDiv {
        span {
          font-size: 16px;
        }
      }
    }
  }
}
