.dashboard-main {
  padding: 22px 63px 14px 45px;
  color: black;

  .dashboard-inner-container {
    width: 100%;
    max-width: 1400px;
    margin: auto;
    border-radius: 20px;
    background: var(--white);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
    padding: 50px 100px;

    .dashboard-inner-box {
      max-width: 1170px;
      margin: auto;


      /* DashboardMain.scss or a separate CSS file */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: var(--primary-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.dashboard-inner-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Or any height suitable for your layout */
}




      .dashboardMain-head-box {
        width: 100%;
        display: flex;
        justify-content: space-between;


        .custom-select {
          background-color: var(--primary-color);;      /* Background color */
          color: white;                  /* Text color */
          border: none;                  /* Remove default border */
          border-radius: 20px;           /* Rounded corners */
          padding: 10px 15px;            /* Add padding */
          font-size: 16px;               /* Increase font size */
          appearance: none;              /* Remove default arrow */
          outline: none;
          cursor: pointer;               /* Add pointer on hover */
          transition: background-color 0.3s ease;
          width: 149px;
        }
        
        .custom-select:hover {
          background-color: var(--primary-color);;     /* Darken background on hover */
        }
        
        /* To style the arrow */
        .custom-select::after {
          content: '▼';                   /* Custom arrow */
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          pointer-events: none;
        }
        


      }

      .dashboardMain-stats-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
        padding: 50px 0px;
        .dashboardMain-stats-box {
          display: flex;
          justify-content: space-between;
          padding: 24px 45px;
          border-radius: 30px;
          box-shadow: var(--shadow-1);
          backdrop-filter: blur(19.32715606689453px);
          .dashboardMain-divide-width {
            width: 50%;
            h6 {
              color: var(--text2);
              font-family: "Open Sans";
              font-size: 18px;
              font-weight: 400;
            }
            h5 {
              color: var(--text3);
              text-align: left;
              font-family: "Open Sans";
              font-size: 50px;
              font-weight: 400;
              span {
                color: var(--text3);
                font-family: "Open Sans";
                font-size: 12px;
                font-weight: 600;
              }
            }
            .back {
              border-radius: 20px;
              background: var(--Local-Variable, #ede8f6);
              box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
              padding: 0px 5px;
            }
            span {
              .icon-phone-box {
                background-color: var(--secondary-color);
                width: 30px;
                height: 30px;
                padding: 5px;
                border-radius: 500px;

                .icon-phone {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
          .bar-position {
            h5 {
              position: relative;

              .bar-content {
                position: absolute;
                top: 0;
                right: 10%;
                width: 60px;
                height: 60px;
                border-radius: 20px;
                background: var(--Local-Variable, #ede8f6);
                box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
                // background: #ede8f6;
              }
            }
          }
        }
      }

      .dashboardMain-graph-container {
        padding: 24px 45px;
        border-radius: 30px;
        box-shadow: var(--shadow-1);
        backdrop-filter: blur(19.32715606689453px);
      }
    }
  }
}

@media only screen and (max-width: 1500px) {
  .dashboard-main {
    .dashboard-inner-container {
      padding: 50px 30px;
      .dashboard-inner-box {
        .dashboardMain-stats-container {
          .dashboardMain-stats-box {
            padding: 25px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1310px) {
  .dashboard-main {
    padding: 22px 20px 14px 20px;

    .dashboard-inner-container {
      width: 100%;
      padding: 30px;

      .dashboard-inner-box {
        .dashboardMain-stats-container {
          grid-template-columns: repeat(2, 1fr);
          gap: 20px;

          .dashboardMain-stats-box {
            padding: 24px 25px;

            .dashboardMain-divide-width {
              justify-content: end;
              gap: 30px;
            }
          }
        }

        .dashboardMain-graph-container {
          padding: 24px 25px;
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .dashboard-main {
    .dashboard-inner-container {
      .dashboard-inner-box {
        .dashboardMain-head-box {
          flex-wrap: wrap;
          gap: 20px;
        }

        .dashboardMain-stats-container {
          grid-template-columns: repeat(1, 1fr);

          .dashboardMain-stats-box {
            .dashboardMain-divide-width {
            }
          }
        }

        .dashboardMain-graph-container {
          padding: 24px 25px;
        }
      }
    }
  }
}

// @media only screen and (max-width: 650px) {
//   .dashboard-main {
//     .dashboard-inner-container {
//       .dashboard-inner-box {
//         .dashboardMain-head-box {
//           flex-direction: column-reverse;
//         }
//         .dashboardMain-stats-container {
//           .dashboardMain-stats-box {
//             // flex-direction: column;
//             .dashboardMain-divide-width {
//               // justify-content: flex-start;
//             }
//           }
//         }
//       }
//     }
//   }
// }

@media only screen and (max-width: 600px) {
  .dashboard-main {
    padding: 10px;

    .dashboard-inner-container {
      padding: 20px;
      .dashboard-inner-box {
        .dashboardMain-head-box {
          .linkPill {
            width: 100%;
          }
          .flexcol {
            flex-direction: column;
            width: 100%;
          }
        }
        .dashboardMain-stats-container {
          .dashboardMain-stats-box {
            justify-content: space-between;
            padding: 24px 10px;
            .dashboardMain-divide-width {
              h6 {
                font-size: 14px;
              }

              h5 {
                font-size: 30px;

                span {
                  color: var(--text3);
                  font-family: "Open Sans";
                  font-size: 12px;
                  font-weight: 600;
                }
              }

              span {
                .icon-phone-box {
                  background-color: var(--secondary-color);
                  width: 30px;
                  height: 30px;
                  padding: 5px;
                  border-radius: 500px;

                  .icon-phone {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
            .bar-position {
              h5 {
                .bar-content {
                  width: 40px;
                  height: 40px;
                  right: 20%;
                }
              }
            }
          }
        }
        .dashboardMain-graph-container {
        }
      }
    }
  }
}
