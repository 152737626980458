.LiveChatBenefits-inner-box {
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-top: 60px;

  .LiveChatBenefits-inner-left-box {
    width: 60%;
    padding: 20px;

    h5 {
      color: #000;
      text-align: left;
      font-family: "Open Sans";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 58.8px;
      padding-bottom: 10px;
    }

    p {
      color: #000;
      font-family: "Open Sans";
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 158.5%;
    }
  }

  .LiveChatBenefits-inner-right-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    border-radius: 20px;
    background: #edf1ff;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;

    h3 {
      color: #000;
      text-align: center;
      font-family: "Open Sans";
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 58.8px;
    }
  }
}

@media only screen and (max-width: 700px) {
  .LiveChatBenefits-inner-box {
    flex-direction: column-reverse;
    margin-top: 30px;

    .LiveChatBenefits-inner-left-box {
      width: 100%;
      padding: 15px;

      h5 {
        font-size: 20px;
      }

      p {
        font-size: 15px;
      }
    }

    .LiveChatBenefits-inner-right-box {
     width: 100%;
      padding: 10px;

      h3 {
        font-size: 24px;
      }
    }
  }
}
