.LiveChatHero-main-container {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 170px;

  .LiveChatHero-text-centered-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: auto;
    color: #fff;

    h1 {
      color: #fff;
      font-family: "Open Sans";
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      // line-height: 58px;
    }

    p {
      color: #fff;
      font-family: "Open Sans";
      font-size: 18.438px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
      max-width: 622px;
      margin-top: 20px;
    }

    .LiveChatHero-left-container {
      width: 40%;
    }

    .LiveChatHero-right-container {
      width: 50%;
      .img-wrapper {
        width: 80%;
        position: relative;
        width: fit-content;
        .inner-child1 {
          position: absolute;
          right: 0;
          bottom: -8%;
          img {
            width: 227px;
            height: 246px;
            position: relative;
            z-index: 2;
          }
        }
        .inner-child2 {
          position: absolute;
          bottom: 5%;
          left: -19%;
          img {
            width: 269px;
            height: 213px;
            position: relative;
            z-index: 3;
          }
        }
      }
    }
  }
}

// Responsive
@media only screen and (max-width: 1600px) {
  .LiveChatHero-main-container {
    .LiveChatHero-text-centered-container {
      p {
        max-width: 422px;
      }
      .LiveChatHero-right-container {
        display: flex;
        justify-content: center;
        align-items: center;

        .img-wrapper {
          img {
            width: 510px;
            height: 490px;
          }
          .inner-child1 {
            right: 0;
            bottom: -8%;
            img {
              width: 200px;
              height: 220px;
            }
          }
          .inner-child2 {
            bottom: 2%;
            left: -19%;
            img {
              width: 229px;
              height: 199px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1400px) {
  .LiveChatHero-main-container {
    padding-top: 100px;
    .LiveChatHero-text-centered-container {
      width: 85%;
      .LiveChatHero-left-container {
        width: 55%;
      }
      .LiveChatHero-right-container {
        width: 45%;
      }
    }
  }
}
@media only screen and (max-width: 1100px) {
  .LiveChatHero-main-container {
    .LiveChatHero-text-centered-container {
      width: 85%;
      .LiveChatHero-left-container {
        width: 43%;
      }
      .LiveChatHero-right-container {
        .img-wrapper {
          img {
            width: 410px;
            height: 440px;
          }
          .inner-child1 {
            img {
              width: 150px;
              height: 190px;
            }
          }
          .inner-child2 {
            img {
              width: 180px;
              height: 159px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 700px) {
  .LiveChatHero-main-container {
    height: fit-content;
    padding: 40px 20px;
    .LiveChatHero-text-centered-container {
      flex-direction: column;
      width: 100%;
      gap: 40px;
      h1 {
        font-size: 32px;
      }

      .LiveChatHero-left-container {
        width: 100%;
      }

      .LiveChatHero-right-container {
        width: 100%;
        .img-wrapper {
          width: 80%;
          position: relative;
          width: fit-content;
          img {
            width: 470px;
            height: 400px;
          }
          .inner-child1 {
            right: 0;
            bottom: -8%;
            img {
              width: 150px;
              height: 180px;
            }
          }
          .inner-child2 {
            bottom: 0%;
            left: -2%;
            img {
              width: 150px;
              height: 170px;
            }
          }
        }
      }
    }
  }
}
