Global styles for the login
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
background: #F6F6F6;
  flex-direction: column;
  position: relative;
  height: 100vh;
  overflow: hidden;
}


.input-group-main{
  display: flex;

  border: 0.98px;
  gap: 16px;
}

.login-box {
  border-radius: 19.1px;
  background: #F6F6F6;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 1022px;
  text-align: center;
  padding: 57px 110px;
}

.login-heading {
  font-size: 24px;
  margin-bottom: 20px;
  /* top: 50.77px; */
  Left:769px;
  color: #5a2d82; /* This matches the purple used in the design */
  Font:"Open Sans";
  font-weight : 700 ;
  line-height : 54.71px;
}

.social-login {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.header-div{
  padding: 16px 66px;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid  rgba(0, 0, 0, 0.20);
}
.header-div .logo{
margin-right: auto;
width: 158.501px;
height: 102.36px;
object-fit: cover;
cursor: pointer
;
}
.header-div h2{
margin: 0;
padding: 0;
margin-right: auto;
}
.login-circle{
  position: absolute;
  left: -228px;
  bottom: -228px;
  z-index: 999;
  width: 590px;
  height: 590px;
  border-radius: 590px;
  opacity: 0.5;
  background: linear-gradient(227deg, #A890D3 13.27%, #4B2E83 75.35%);
  filter: blur(100.1500015258789px);
}

.social-button {
  width: 420px;
  height: 55.82PX;
  border: 1px solid black;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  
}

.social-button.google {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgb(54, 54, 54);
  margin-left: 20px;
}

.social-button.facebook {
  background-color: rgba(0, 0, 0, 0.1);
  color: white;

  color: rgb(54, 54, 54);
  margin-right: 20px;
}

.divider {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 14px;
  color: #888;
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  height: 1px;
  background-color: #ddd;
  margin: 0 10px;
}

.input-group {
width: 100%;
display: flex;
flex-direction: column;
row-gap: 6px;
}

.input-group label {
  color: rgba(0, 0, 0, 0.50);
  font-family: "Open Sans";
  font-size: 13.711px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.137px;
  text-align: left;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block;
}

.input-group input {
  border-radius: 9.794px;
  border: 0.979px solid rgba(0, 0, 0, 0.20);
  padding: 20px;
  width: 100%;
  color: black;
font-family: "Open Sans";
font-size: 13.711px;
font-style: italic;
font-weight: 400;
line-height: normal;
letter-spacing: 0.137px;
outline: none;
box-shadow: none;
-webkit-user-select: none;
-webkit-touch-callout: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
display: block;
}
.input-group input::placeholder{
  opacity: 0.5;
}

.submitBtn {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  color: white;
  background-color: #5a2d82; /* Purple button color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  -webkit-user-select: none;
        -webkit-touch-callout: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
}

.submitBtn:hover {
  background-color: #4b2373;
}

.form-footer {
  margin-top: 20px;
  font-size: 14px;
  color: #5a2d82;
}

.signup-link {
  display: block;
  margin-top: 10px;
  color: #5a2d82;
  text-decoration: none;
}

.signup-link:hover {
  text-decoration: underline;
}

.error {
  color: red;
  margin-bottom: 10px;
  font-size: 14px;
}


.form-actions {
  display: flex;
  justify-content: space-between; /* Places the elements at the extremes */
  align-items: center; /* Aligns the link and button vertically in the middle */
  margin-top: 20px;
}

.form-actions a {
  font-size: 14px;
  color: #5a2d82; /* Color for the link */
  text-decoration: none;
}

.form-actions a:hover {
  text-decoration: underline; /* Underline on hover */
}

.submitBtn {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background: linear-gradient(226.51deg, #A890D3 13.27%, #4B2E83 75.35%);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: var(--tranition-half); /* Smooth color transition */
}

.submitBtn:hover {
  background-color: #4b2373; /* Darker color on hover */
}


.LLM-input-box {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #e5e5e5 !important;

 
}
.LLM-input-box svg {
  position: absolute;
  right: 20px;
  cursor: pointer;
}

@media screen and (max-width:1200px) {

  .login-box {
    max-width: 800px;
    padding: 50px 70px;
  }


  .login-circle{

display: none;

  }
  
}

@media screen and (max-width:900px) {

  .login-box {
    max-width: calc(100% - 40px);
    padding: 30px 40px;

  }


  .login-circle{

display: none;

  }
  
  .login-container {
  height: unset;
  min-height: 100vh;
  }
  .header-div{
    padding: 16px 30px;


  }
  .header-div .logo{
  width: 120px;
  height: 80px;
  object-fit: contain;
  ;
  }
  .header-div h2{
font-size: 20px;
  }
  .input-group-main{
flex-direction: column;
row-gap: 20px;
  }
}
