.integrationWrapper {
  padding: 20px;
  background: var(--black-grad, linear-gradient(90deg, #000 0%, #474747 100%));
  padding-top: 130px;
  padding-bottom: 130px;

  .inte-card-Container {
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    // margin-top: 50px;
    margin-bottom: 50px;

    .integrationCard {
      width: 100%;
      max-width: 680px !important;
      // height: 457px;
      background: white;
      display: flex;
      padding: 20px 20px 20px 36px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 24px;
      border-radius: 20px;
      background: #fff;
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);

      h6 {
        color: #000;
        font-family: "Open Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px;
        /* 145.833% */
      }

      p {
        color: #000;
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 158.5%;
        /* 22.19px */
        max-width: 466px;
      }
    }
  }

  .tailoredSol {
    width: 80%;
    margin: 0 auto;
    color: white;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 24px;
    //padding: 20px;

    h6 {
      color: #fff;
      font-family: "Open Sans";
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 35px;
      text-align: center;
      margin: auto;
      margin-top: 40px;
    }

    .mediumText {
      color: #fff;
      text-align: center;
      margin: auto;
      font-family: "Open Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 158.5%;
    }

    .smallText {
      color: #fff;
      margin: auto;
      text-align: center;
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }
}

@media screen and (max-width: 1270px) {
  .integrationWrapper {
    .inte-card-Container {
      width: 100%;

      .integrationCard {
        height: 425px;
        justify-content: space-between !important;

        img {
          width: 170px;
        }
      }
    }

    .tailoredSol {
      width: 100%;

      .mediumText {
        text-align: left;
      }

      .smallText {
        text-align: left;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .integrationWrapper {
    padding-top: 30px;
    padding-bottom: 30px;

    .inte-card-Container {
      flex-direction: column;

      .integrationCard {
        width: 100%;
        height: auto;
        padding: 20px;

        img {
          width: 140px;
        }
      }
    }
    .tailoredSol {
      h6 {
        text-align: center;
        font-size: 18px !important;
        line-height: normal;
      }
      p {
        font-size: 14px !important;
        text-align: center !important;
      }
    }
  }
}
