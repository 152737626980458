.automatedResponses-main-container {
 // border: 2px solid orange;
  .heading-element {
    width: fit-content;
    margin: auto;
    h2 {
      color: #fff;
      text-align: center;
      font-family: "Open Sans";
      font-size: 40.195px;
      font-style: normal;
      font-weight: 700;
      line-height: 58.8px;
      padding: 80px 0px 20px 0px;
      position: relative;
    }
    .head-bottom-line {
      width: 134px;
      height: 3px;
      margin: auto;
      margin-top: 5px;
      border-radius: 5px;
      transition: 0.7s ease-in-out;
      background: var(
        --3-color-grad,
        linear-gradient(90deg, #4b2e83 0%, #a890d3 52%, #4b2e83 100%)
      );
    }
    &:hover {
      .head-bottom-line {
        width: 90%;
      }
    }
  }

  .automatedResponses-centered-container {
    width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 159px;
    padding: 60px 0px 0px 0px;

    .automatedResponses-left-container {
      width: 50%;

      p {
        color: #fff;
        font-family: "Open Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
      }
    }

    .automatedResponses-right-container {
      width: 50%;

      img {
        border-radius: 10px;
      }
    }
  }
}
@media only screen and (max-width: 1600px) {
  .automatedResponses-main-container {
    .automatedResponses-centered-container {
      gap: 59px;
    }
  }
}
@media only screen and (max-width: 1300px) {
  .automatedResponses-main-container {
    .automatedResponses-centered-container {
      gap: 30px;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .automatedResponses-centered-container {
    flex-direction: column;
    .automatedResponses-left-container {
      width: 100% !important;
    }
    .automatedResponses-right-container {
      width: 100% !important;
    }
  }
}
@media only screen and (max-width: 600px) {
  .automatedResponses-main-container {
    // padding: 0px 20px;
    h2 {
      font-size: 24px !important;
      padding: 50px 0px 0px 0px !important;
    }
    .head-bottom-line{
      margin-top: -10px;
    }

    .automatedResponses-centered-container {
      width: 90%;
      gap: 30px;
      padding: 30px 0px 0px 0px;

      .automatedResponses-left-container {
        width: 100% !important;

        p {
          font-size: 14px;
          text-align: center;
        }
      }
    }
  }
}
