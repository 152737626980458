.NameBox-box {
    h5 {
        color: var(--black);
        font-family: "Open Sans";
        font-size: 29.931px;
        font-weight: 600;
        text-align: left;
    }

    input {
        display: flex;
        padding: 19.954px 28.684px;
        justify-content: center;
        align-items: center;
        gap: 12.471px;
        margin: 10px 0px;
        width: 100%;
        border-radius: 9.977px;
        border: 1.247px solid var(--light-bg);

        &:focus {
            border: 1.247px solid var(--light-bg);
        }
    }

    p {
        color: var(--text1);
        font-family: "Open Sans";
        font-size: 19.954px;
        text-align: left;
        font-weight: 400;
    }
}

@media only screen and (max-width: 700px) {
    .NameBox-box {
        h5 {
            font-size: 20px !important;
        }

        input {
            display: flex;
            margin: 0px 0px 10px 0px;
        }

        p {
            font-size: 14px;
        }

        .theme-btn-light {
            padding: 10px 30px !important; 
            font-size: 16px !important;
        }
    }

}