.DataLoader-container {
  max-height: 600px;
  overflow-y: scroll;
  padding-left: 5px;
  padding-right: 5px;
  h5 {
    color: var(--black);
    font-family: "Open Sans";
    font-size: 29.931px;
    font-weight: 600;
    text-align: left;
  }

  p {
    color: var(--text1);
    font-family: "Open Sans";
    font-size: 19.954px;
    text-align: left;
    font-weight: 400;
  }

  .DataLoader-inner-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .DataLoader-box {
      width: 100%;
      padding: 20px;
      border-radius: 9.453px;
      background: var(--light-bg);
      border: 1px solid #fff;
      cursor: pointer;
      span {
        width: 61px;
        height: 61px;
        border-radius: 500px;
        background-color: #7d5ba6;
      }

      h5 {
        color: var(--black);
        font-family: "Open Sans";
        font-size: 20px;
        font-weight: 600;
      }

      p {
        color: var(--text1);
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: 400;
      }

      .loaderFaq-box {
        label {
          color: #000;
          font-family: "Open Sans";
          font-size: 18.541px;
          font-weight: 500;
        }

        input {
          width: 100%;
          padding: 15px 20px;
          border-radius: 9.27px;
          margin-top: 5px;
          margin-bottom: 5px;
          background: var(--tabele-head-bg);
          border: none;

          &::placeholder {
            color: #343434;
            font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
            font-family: "Open Sans";
            font-size: 16px;
            font-weight: 400;
          }
        }

        textarea {
          padding: 12px;
          width: 100%;
          margin-top: 5px;
          border-radius: 9.27px;
          background: var(--tabele-head-bg);
          border: none;
          resize: none;

          &::placeholder {
            color: #343434;
            font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
            font-family: "Open Sans";
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
      .url-box {
        label {
          color: #000;
          font-family: "Open Sans";
          font-size: 18.541px;
          font-weight: 500;
        }

        input {
          width: 100%;
          padding: 15px 20px;
          border-radius: 9.27px;
          margin-top: 5px;
          margin-bottom: 5px;
          background: var(--tabele-head-bg);
          border: none;

          &::placeholder {
            color: #343434;
            font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
            font-family: "Open Sans";
            font-size: 16px;
            font-weight: 400;
          }
        }
        p {
          color: #343434;
          font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
          font-family: "Open Sans";
          font-size: 16px;
          font-style: italic;
          font-weight: 400;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .DataLoader-container {
    max-height: unset;
    .DataLoader-inner-container {
      .DataLoader-box {
        padding: 10px;
        span {
          width: 35px;
          height: 35px;
        }
        h5 {
          font-size: 16px;
        }
        p {
          font-size: 12px;
        }
      }
    }
  }
}
