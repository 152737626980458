.platform-card-wrapper {
  padding: 20px;
  //border: 2px solid green;

  h5 {
    color: #fff;
    text-align: center;
    font-family: "Open Sans";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 58.8px; /* 196% */
  }
  .purpleLine {
    margin-top: 20px !important;
    width: 134px;
    margin: 0 auto;
    height: 3px;
    border-radius: 5px;
    background: var(
      --3-color-grad,
      linear-gradient(90deg, #4b2e83 0%, #a890d3 52%, #4b2e83 100%)
    );
  }
  .mainContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    margin-top: 50px;
    //border: 2px solid blue;
    .leftArea {
      width: 50%;
      p {
        color: #fff;
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 158.5%; /* 22.19px */
      }
    }
    .rightArea {
      width: 38%;
      height: 260px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
.cardSwapping {
  flex-direction: row-reverse !important;
}

@media screen and (max-width: 1670px) {
  .platform-card-wrapper .mainContent .rightArea img {
    object-fit: contain;
  }
}
@media screen and (max-width: 1500px) {
  .platformWrapper {
    .headingDiv {
      h3 {
        font-size: 40px;
      }
      h4 {
        font-size: 31px;
        line-height: normal;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .platform-card-wrapper {
    padding: 0;
    h5 {
      font-size: 25px;
      line-height: normal;
    }
    .purpleLine {
      margin-top: 0 !important;
    }
    .mainContent {
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      margin-top: 30px;
      .leftArea {
        width: 100%;
      }
      .rightArea {
        width: 100%;
        height: auto;
        img {
          width: 300px;
          height: 200px;
          margin: 0 auto;
        }
      }
    }
  }
  .cardSwapping {
    flex-direction: column !important;
  }
}
