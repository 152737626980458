.chat-bot-container {
  position: relative;
  z-index: 4;

  .chat-bot-main {
    width: 83.901px;
    height: 84.031px;
    position: fixed;
    bottom: 2%;
    right: 6%;
    cursor: pointer;
  }

  .chat-bot {
    width: 327px;
    height: fit-content;
    border-radius: 8.906px;
    background: #fff;
    box-shadow: 1.052px 24.73px 36.305px 0px rgba(25, 24, 24, 25);
    position: fixed;
    z-index: 15;
    bottom: 14%;
    right: 5%;

    .chat-top {
      padding: 20px 18px;
      background: #4B2E83;
      border-top-left-radius: 8.906px;
      border-top-right-radius: 8.906px;

      .left {
        img {
          width: 30px;
          // height: 25.606px;
        }

        h6 {
          color: var(--primary-dark, #fff);
          font-family: "Open Sans";
          font-size: 12px;
          letter-spacing: 1px;
          font-style: normal;
          font-weight: 600;
          line-height: 158.5%;
        }
      }
    }

    .chat-middle {
      padding: 11px 10px 22px 10px;
      height: 548px;
      overflow-y: scroll;
      width:500px ;

      &::-webkit-scrollbar {
        width: 12px;
        // background: #b4acac;
        // border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb {
        // background: var(--primary-gradient-bg);
        border-radius: 24px;
      }

      .received-txt-div {
        position: relative;

        span {
          background-color: #e2e2e2;
          position: absolute;
          border-radius: 50px;
          padding: 5px;
          top: -12px;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 100%;
          }
        }
      }

      .sender-txt-div {
        justify-content: right;
      }

      .chatBot-welcomeTxt {
        color: #0e0e0e;
        text-align: center;
        font-size: 14px;
        border: 1px solid #e2e2e2;
        width: fit-content;
        padding: 10px;
        margin: auto;
        border-radius: 10px;

        span {
          color: #4B2E83;
          font-weight: 700;
        }
      }

      .timer-item {
        p {
          color: #fff;
          font-family: "Open Sans";
          font-size: 7.627px;
          font-style: normal;
          font-weight: 400;
          line-height: 158.5%;
          /* 12.089px */
          border-radius: 14.339px;
          background: var(--primary-dark, #4b2e83);
          padding: 0.305px 4.881px;
          width: fit-content;
          margin: 10px auto;
        }
      }
    }

    .chat-bottom {
      padding: 13px 15.506px 13px 10px;
      background: #f0f0f0;
      border-bottom-left-radius: 8.906px;
      border-bottom-right-radius: 8.906px;

      .bottom-opt-box {
        display: flex;
        justify-content: space-between;
      }

      input {
        color: black;
        font-family: "Open Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 158.5%;
        /* 19.02px */
        background: transparent;
        width: 100%;
        height: 19px;
        outline: none !important;
        border: none !important;
      }

      input:focus {
        outline: 1px solid #f0f0f0 !important;
        border: 1px solid #f0f0f0 !important;
      }
    }
  }

  .chat-agent {
    .chat-top {
      padding: 10px 18px;
      background: #f0f0f0;
      border-top-left-radius: 8.906px;
      border-top-right-radius: 8.906px;

      .left {
        img {
          width: 49.038px;
          height: 46.313px;
        }

        h6 {
          color: var(--primary-dark, #4b2e83);
          font-family: Arial;
          font-size: 18.123px;
          font-style: normal;
          font-weight: 700;
          line-height: 158.5%;
          /* 28.724px */
        }
      }
    }




  }
}


.footer-icon {
  cursor: pointer !important;
  transform: rotate(45deg);
}

.loader {
  width: 60px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #4B2E83 90%, #ffffff00);
  background:
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/2) 30%;
  animation: l3 1s infinite linear;
  margin-top: 10px;
}

@keyframes l3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%
  }

  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%
  }

  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%
  }

  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%
  }
}
 
.chat-bot-container{
  .copy{
    width: unset;
    position: unset;
  }
}

// Emojig Box
// .emoji-picker-container {
.epr-main {
  height: 300px !important;
  width: auto !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  margin-top: 110px !important;

  .epr_q53mwh.epr_vl50xg.epr_-3yva2a.epr_c90x4z.epr_-sisw4f.epr_mw4zr1.epr_-kg0voo.epr_iogimd.epr_wtwvf4.epr_-lzq3oa {
    display: none;
  }
}

// }

// Emojig Box




@media only screen and (max-width: 1400px) {
  .chat-bot-container {
    // border: 3px solid red;

    .chat-bot-main {
      width: 60px;
      height: 60px;
      position: fixed;
      bottom: 2%;
      right: 6%;
      cursor: pointer;
    }

    .chat-bot {
      width: 327px;
      height: 350px;
      bottom: 19.5%;
      right: 6.5%;

      .chat-middle {
        height: 285px;
      }

      .chat-top {}
    }
  }
}

@media only screen and (max-width: 600px) {
  .chat-bot-container {
    .chat-agent {
      .chat-middle {
        .received-txt-div {
          .received-txt {
            font-size: 13px;
            padding: 10px;
          }
        }

        .sender-txt-div {
          .sender-txt {
            font-size: 13px;
            padding: 10px;
          }
        }
      }
    }

    .chat-bot-main {
      width: 63.901px;
      height: 64.031px;
    }
  }
}

[multiple]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="email"]:focus,
[type="month"]:focus,
[type="number"]:focus,
[type="password"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="text"]:focus,
[type="time"]:focus,
[type="url"]:focus,
[type="week"]:focus,
select:focus,
textarea:focus {
  --tw-ring-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
  outline-offset: unset !important;
}