.workCardWrapper {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  width: 100%;
  // height: 219px;
  padding: 20px 20px 28px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 36px;

  .grayDiv {
    display: flex;
    width: 100%;
    height: 79px;
    padding: 10px 10px 10px 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 500px;
    background: #edf1ff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.16);

    .boldText {
      color: #000;
      text-align: center;
      font-family: "Open Sans";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 58.8px; /* 245% */
    }
  }
  p {
    width: 600px;
    margin: 0 auto;
    color: #000;
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 158.5%; /* 22.19px */
  }
}
@media screen and (max-width: 600px) {
  .workCardWrapper {
    .grayDiv {
      display: flex;
      width: 100%;
      height: auto;
      .boldText {
        line-height: normal;
        font-size: 21px;
        padding: 0;

      }
    }
    p{
        width: 100%;
    }
  }
}
